import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { GenericDialog, isNullish } from "@igloocloud/igloosharedui";
import { DeleteOutlined } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  MenuItem,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TransitionGroup } from "react-transition-group";
import tinyColor from "tinycolor2";
import {
  Action,
  ActionType,
  Condition,
  FloatComparison,
  IntervalUnit,
  Props,
} from "./FlowManifestDialog.types";
import moment from "moment";

const AndButton = styled(Button)({
  "&.MuiButton-outlinedInherit": {
    borderColor: tinyColor(process.env.REACT_APP_TEXT_ON_MAIN_BACKGROUND_COLOR)
      .setAlpha(0.26)
      .toHex8String(),
    "&:hover": {
      borderColor: tinyColor(
        process.env.REACT_APP_TEXT_ON_MAIN_BACKGROUND_COLOR
      )
        .setAlpha(0.87)
        .toHex8String(),
    },
  },
});

const makePluralAndLowercase = (value) =>
  value.charAt(0).toLowerCase() + value.slice(1) + "s";

export default ({ open, close, mode, thingId, selectedFlow }: Props) => {
  const [name, setName] = useState<string>("");
  const [nameEmpty, setNameEmpty] = useState<boolean>(false);
  const [conditions, setConditions] = useState<Condition[]>([
    { localId: Date.now() },
  ]);
  const [actions, setActions] = useState<Action[]>([{ localId: Date.now() }]);
  const [intervalDuration, setIntervalDuration] = useState<number>(null);
  const [intervalDurationEmpty, setIntervalDurationEmpty] =
    useState<boolean>(null);
  const [intervalUnit, setIntervalUnit] = useState<IntervalUnit>("minutes");

  const { t }: { t: (string) => string } = useTranslation();

  const [getVariables, { data }] = useLazyQuery(
    gql`
      query ($id: ID!, $limit: NaturalNumber!, $offset: NaturalNumber) {
        thing(id: $id) {
          id
          variableCount
          variables(limit: $limit, offset: $offset, sortBy: index) {
            __typename
            id
            name
            ... on StringVariable {
              stringAllowedValues: allowedValues
            }
            ... on FloatVariable {
              floatAllowedValues: allowedValues
              min
              max
            }
            ... on FloatSeriesVariable {
              minThreshold
              maxThreshold
              visualization
            }
          }
        }
      }
    `,
    { variables: { id: thingId, limit: 20, offset: 0 } }
  );
  const [createFlow] = useMutation(gql`
    mutation createFlow(
      $thingId: ID!
      $name: String!
      $minInterval: Int!
      $manifest: Json!
    ) {
      createFlow(
        thingId: $thingId
        developerOnly: false
        name: $name
        manifest: $manifest
        minInterval: $minInterval
      ) {
        id
      }
    }
  `);
  const [updateFlow] = useMutation(gql`
    mutation updateFlow(
      $id: ID!
      $name: String!
      $minInterval: Int!
      $manifest: Json!
    ) {
      updateFlow(
        id: $id
        developerOnly: false
        name: $name
        minInterval: $minInterval
        manifest: $manifest
      ) {
        id
      }
    }
  `);

  useEffect(() => {
    if (open) {
      setNameEmpty(false);
      setIntervalUnit("minutes");
      setIntervalDurationEmpty(false);

      getVariables();

      if (mode === "create") {
        setName("");
        setConditions([{ localId: Date.now() }]);
        setActions([{ localId: Date.now() }]);
        setIntervalDuration(null);
      } else {
        setName(selectedFlow.name);
        setConditions(
          selectedFlow.manifest.conditions.map((condition) => ({
            ...condition,
            localId: Date.now(),
          }))
        );
        setActions(
          selectedFlow.manifest.actions.map((condition) => ({
            ...condition,
            localId: Date.now(),
          }))
        );
        setIntervalDuration(selectedFlow.minInterval / 1000 / 60);
      }
    }
  }, [mode, open, selectedFlow]);

  const {
    REACT_APP_TEXT_ON_MAIN_BACKGROUND_COLOR: textOnMainBackgroundColor,
  }: {
    REACT_APP_TEXT_ON_MAIN_BACKGROUND_COLOR: string;
  } = process.env;

  const showInterval =
    conditions.filter(
      (condition) =>
        Object.keys(condition).filter((key) => key !== "localId").length
    ).length && conditions.every(({ type }) => type !== "timer");

  return (
    <GenericDialog
      title={mode === "create" ? t`Create flow` : t`Edit flow`}
      open={open}
      close={close}
      containedButton={mode === "create" ? t`Create` : t`Edit`}
      containedButtonFunction={() =>
        mode === "create"
          ? createFlow({
              variables: {
                thingId,
                name,
                developerOnly: false,
                minInterval: showInterval
                  ? intervalDuration *
                    (intervalUnit === "minutes"
                      ? 60
                      : intervalUnit === "hours"
                      ? 3_600
                      : intervalUnit === "days"
                      ? 86_400
                      : 2_592_000)
                  : 60 * 60 * 1000,
                manifest: {
                  conditions: conditions.map(
                    ({ id, schedule, type, comparison, value }) => ({
                      ...(id
                        ? {
                            id: id.substring(id.indexOf("-") + 1),
                          }
                        : {}),
                      type: type ?? id.substring(0, id.indexOf("-")),
                      comparison,
                      ...(value
                        ? {
                            value: parseFloat(value as string),
                          }
                        : {}),
                      ...(schedule
                        ? {
                            schedule:
                              schedule === "day"
                                ? {
                                    hour: "*",
                                    day: "1",
                                    month: "*",
                                    dayOfWeek: "*",
                                  }
                                : schedule === "month"
                                ? {
                                    hour: "*",
                                    day: "*",
                                    month: "1",
                                    dayOfWeek: "*",
                                  }
                                : {
                                    hour: "*",
                                    day: "*",
                                    month: "12",
                                    dayOfWeek: "*",
                                  },
                          }
                        : {}),
                    })
                  ),
                  actions: actions.map(
                    ({
                      type,
                      value,
                      content,
                      floatSeriesVariables,
                      floatVariables,
                      stringVariables,
                      booleanVariables,
                      kc,
                      labParameters,
                    }) => ({
                      type,
                      ...(value ? { value } : {}),
                      ...(content ? { content } : {}),
                      ...(type === "storeOnBlockchain"
                        ? { floatSeriesVariables: floatSeriesVariables ?? [] }
                        : {}),
                      ...(type === "storeOnBlockchain"
                        ? { floatVariables: floatVariables ?? [] }
                        : {}),
                      ...(type === "storeOnBlockchain"
                        ? { stringVariables: stringVariables ?? [] }
                        : {}),
                      ...(type === "storeOnBlockchain"
                        ? { booleanVariables: booleanVariables ?? [] }
                        : {}),
                      ...(type === "bilancioIdrico"
                        ? { kc, labParameters }
                        : {}),
                    })
                  ),
                },
              },
            }).then(close)
          : updateFlow({
              variables: {
                id: selectedFlow.id,
                name,
                developerOnly: false,
                minInterval: showInterval
                  ? intervalDuration *
                    (intervalUnit === "minutes"
                      ? 60
                      : intervalUnit === "hours"
                      ? 3_600
                      : intervalUnit === "days"
                      ? 86_400
                      : 2_592_000)
                  : 60 * 60 * 1000,
                manifest: {
                  conditions: conditions.map(
                    ({ id, schedule, type, comparison, value }) => ({
                      ...(id
                        ? {
                            id: id.substring(id.indexOf("-") + 1),
                          }
                        : {}),
                      type: type ?? id.substring(0, id.indexOf("-")),
                      comparison,
                      ...(value
                        ? {
                            value: parseFloat(value as string),
                          }
                        : {}),
                      ...(schedule
                        ? {
                            schedule:
                              schedule === "day"
                                ? {
                                    hour: "*",
                                    day: "1",
                                    month: "*",
                                    dayOfWeek: "*",
                                  }
                                : schedule === "month"
                                ? {
                                    hour: "*",
                                    day: "*",
                                    month: "1",
                                    dayOfWeek: "*",
                                  }
                                : {
                                    hour: "*",
                                    day: "*",
                                    month: "12",
                                    dayOfWeek: "*",
                                  },
                          }
                        : {}),
                    })
                  ),
                  actions: actions.map(
                    ({
                      type,
                      value,
                      content,
                      floatSeriesVariables,
                      floatVariables,
                      stringVariables,
                      booleanVariables,
                    }) => ({
                      type,
                      ...(value ? { value } : {}),
                      ...(content ? { content } : {}),
                      ...(type === "storeOnBlockchain"
                        ? { floatSeriesVariables: floatSeriesVariables ?? [] }
                        : {}),
                      ...(type === "storeOnBlockchain"
                        ? { floatVariables: floatVariables ?? [] }
                        : {}),
                      ...(type === "storeOnBlockchain"
                        ? { stringVariables: stringVariables ?? [] }
                        : {}),
                      ...(type === "storeOnBlockchain"
                        ? { booleanVariables: booleanVariables ?? [] }
                        : {}),
                    })
                  ),
                },
              },
            }).then(close)
      }
      containedButtonDisabled={
        !name ||
        conditions.some((condition) =>
          Object.values(condition).some(isNullish)
        ) ||
        actions.some((action) => Object.values(action).some(isNullish)) ||
        (showInterval && !intervalDuration)
      }
      textButton={t`Close`}
      textButtonFunction={close}
      disableMaxHeight
    >
      <TextField
        value={name}
        onChange={(event) => {
          setName(event.target.value);
          setNameEmpty(event.target.value === "");
        }}
        error={nameEmpty}
        label={t`Name`}
        variant="outlined"
        required
        style={{
          width: "100%",
          marginBottom: "24px",
        }}
        InputLabelProps={name ? { shrink: true } : {}}
      />
      <TransitionGroup>
        {conditions.map(
          (
            { id, type, schedule, comparison, value, valueEmpty, localId },
            index
          ) => (
            <Collapse key={"condition-" + localId}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "16px",
                  height: "48px",
                }}
              >
                <Typography
                  variant="h6"
                  style={{
                    width:
                      conditions.length === 1 ? "100%" : "calc(100% - 48px)",
                    margin: 0,
                  }}
                >
                  {index === 0 ? t`If` : t`And`}
                </Typography>
                {conditions.length === 1 ? null : (
                  <IconButton
                    onClick={() =>
                      setConditions((conditions) => [
                        ...conditions.filter(
                          (_, indexToRemove) => indexToRemove !== index
                        ),
                      ])
                    }
                    size="large"
                  >
                    <DeleteOutlined />
                  </IconButton>
                )}
              </div>
              <TextField
                value={id}
                onChange={(event) => {
                  setConditions((conditions) => {
                    const newConditions = [...conditions]; //clone to refresh React state

                    if (event.target.value === "timer") {
                      newConditions[
                        conditions.findIndex(
                          ({ localId: idToFind }) => idToFind === localId
                        )
                      ].type = event.target.value;

                      newConditions[
                        conditions.findIndex(
                          ({ localId: idToFind }) => idToFind === localId
                        )
                      ].schedule = "day";
                    } else {
                      newConditions[
                        conditions.findIndex(
                          ({ localId: idToFind }) => idToFind === localId
                        )
                      ].id = event.target.value;
                      delete newConditions[
                        conditions.findIndex(
                          ({ localId: idToFind }) => idToFind === localId
                        )
                      ].type;
                    }

                    return newConditions;
                  });
                }}
                label={t`Variable`}
                variant="outlined"
                select
                required
                style={{
                  width: "100%",
                  marginBottom: "24px",
                }}
                InputLabelProps={id ? { shrink: true } : {}}
              >
                <MenuItem
                  style={{ color: textOnMainBackgroundColor }}
                  value="timer"
                  key="create-flow-dialog-variable-list-timer"
                >
                  {t`Timer ticks`}
                </MenuItem>
                {data?.thing.variables.map(
                  ({
                    id,
                    name,
                    __typename,
                    visualization,
                    minThreshold,
                    maxThreshold,
                  }) => [
                    <MenuItem
                      style={{ color: textOnMainBackgroundColor }}
                      value={"floatSeriesVariableLastNode-" + id}
                      key={"create-flow-dialog-variable-list-" + id}
                    >
                      {(__typename === "FloatSeriesVariable"
                        ? t`The last node of` + " "
                        : "") +
                        name +
                        " " +
                        (__typename === "ImpulseVariable"
                          ? t`triggers`
                          : t`is`)}
                    </MenuItem>,
                    __typename === "FloatSeriesVariable" &&
                      visualization !== "windDirection" &&
                      (!isNullish(minThreshold) ||
                        !isNullish(maxThreshold)) && (
                        <MenuItem
                          style={{
                            color: textOnMainBackgroundColor,
                          }}
                          value={"threshold-" + id}
                          key={"create-flow-dialog-variable-list-" + id}
                        >
                          {t`The last node of` +
                            " " +
                            name +
                            " " +
                            t`is outside the thresholds`}
                        </MenuItem>
                      ),
                  ]
                )}
              </TextField>
              <Collapse
                key={"condition-options-" + localId}
                in={!!id || !!type}
              >
                {type === "timer" ? (
                  <ToggleButtonGroup
                    exclusive
                    fullWidth
                    style={{
                      marginBottom: "24px",
                    }}
                    onChange={(_, value) =>
                      setConditions((conditions) => {
                        const newConditions = [...conditions]; //clone to refresh React state

                        newConditions[
                          conditions.findIndex(
                            ({ localId: idToFind }) => idToFind === localId
                          )
                        ].schedule = value;

                        return newConditions;
                      })
                    }
                    value={schedule}
                  >
                    <ToggleButton value="day">{t`Every day`}</ToggleButton>
                    <ToggleButton value="month">{t`Every month`}</ToggleButton>
                    <ToggleButton value="quarter">{t`Every year`}</ToggleButton>
                  </ToggleButtonGroup>
                ) : (
                  data?.thing.variables.find(
                    ({ id: idToFind }) =>
                      idToFind ===
                      id
                        ?.replace("threshold-", "")
                        .replace("floatSeriesVariableLastNode-", "")
                  ) &&
                  (data?.thing.variables.find(
                    ({ id: idToFind }) =>
                      idToFind ===
                      id
                        ?.replace("threshold-", "")
                        .replace("floatSeriesVariableLastNode-", "")
                  ).__typename === "FloatVariable" ||
                  data?.thing.variables.find(
                    ({ id: idToFind }) =>
                      idToFind ===
                      id
                        ?.replace("threshold-", "")
                        .replace("floatSeriesVariableLastNode-", "")
                  ).__typename === "FloatSeriesVariable" ? (
                    <>
                      <TextField
                        value={comparison}
                        onChange={(event) =>
                          setConditions((conditions) => {
                            const newConditions = [...conditions]; //clone to refresh React state

                            newConditions[
                              conditions.findIndex(
                                ({ localId: idToFind }) => idToFind === localId
                              )
                            ].comparison = event.target
                              .value as FloatComparison;

                            return newConditions;
                          })
                        }
                        label={t`Operator`}
                        variant="outlined"
                        select
                        required
                        style={{
                          width: "calc(100% - 160px)",
                          marginBottom: "24px",
                          marginRight: "16px",
                        }}
                        InputLabelProps={comparison ? { shrink: true } : {}}
                      >
                        <MenuItem
                          style={{
                            color: textOnMainBackgroundColor,
                          }}
                          value="equal"
                          key="equal"
                        >
                          {t`Equal to`}
                        </MenuItem>
                        <MenuItem
                          style={{
                            color: textOnMainBackgroundColor,
                          }}
                          value="notEqual"
                          key="notEqual"
                        >
                          {t`Not equal to`}
                        </MenuItem>
                        <MenuItem
                          style={{
                            color: textOnMainBackgroundColor,
                          }}
                          value="greaterThan"
                          key="greaterThan"
                        >
                          {t`Greater than`}
                        </MenuItem>
                        <MenuItem
                          style={{
                            color: textOnMainBackgroundColor,
                          }}
                          value="greaterThanOrEqual"
                          key="greaterThanOrEqual"
                        >
                          {t`Greater than or equal to`}
                        </MenuItem>
                        <MenuItem
                          style={{
                            color: textOnMainBackgroundColor,
                          }}
                          value="lessThan"
                          key="lessThan"
                        >
                          {t`Less than`}
                        </MenuItem>
                        <MenuItem
                          style={{
                            color: textOnMainBackgroundColor,
                          }}
                          value="lessThanOrEqual"
                          key="lessThanOrEqual"
                        >
                          {t`Less than or equal to`}
                        </MenuItem>
                      </TextField>
                      <TextField
                        label={t`Value`}
                        type="number"
                        value={value}
                        variant="outlined"
                        required
                        error={valueEmpty}
                        onChange={(event) =>
                          setConditions((conditions) => {
                            const newConditions = [...conditions]; //clone to refresh React state
                            const conditionIndex: number =
                              newConditions.findIndex(
                                ({ localId: idToFind }) => idToFind === localId
                              );

                            newConditions[conditionIndex].value =
                              event.target.value;

                            newConditions[conditionIndex].valueEmpty =
                              event.target.value.replace(/\s/g, "") === "";

                            return newConditions;
                          })
                        }
                        style={{
                          width: "144px",
                        }}
                        InputLabelProps={value ? { shrink: true } : {}}
                      />
                    </>
                  ) : id?.startsWith("threshold-") &&
                    !isNullish(
                      data?.thing.variables.find(
                        ({ id }) => id === id?.replace("threshold-", "")
                      ).minThreshold
                    ) &&
                    !isNullish(
                      data?.thing.variables.find(
                        ({ id }) => id === id?.replace("threshold-", "")
                      ).maxThreshold
                    ) ? (
                    <TextField
                      value={value}
                      onChange={(event) =>
                        setConditions((conditions) => {
                          const newConditions = [...conditions]; //clone to refresh React state

                          newConditions[
                            conditions.findIndex(
                              ({ localId: idToFind }) => idToFind === localId
                            )
                          ].value = event.target.value;

                          return newConditions;
                        })
                      }
                      label={t`Threshold`}
                      variant="outlined"
                      select
                      required
                      style={{
                        width: "100%",
                        marginBottom: "24px",
                      }}
                      InputLabelProps={value ? { shrink: true } : {}}
                    >
                      <MenuItem
                        style={{
                          color: textOnMainBackgroundColor,
                        }}
                        value="minThreshold"
                        key={localId + "-min-threshold"}
                      >
                        {t`Lower`}
                      </MenuItem>
                      <MenuItem
                        style={{
                          color: textOnMainBackgroundColor,
                        }}
                        value="maxThreshold"
                        key={localId + "-max-threshold"}
                      >
                        {t`Upper`}
                      </MenuItem>
                    </TextField>
                  ) : data?.thing.variables.find(
                      ({ id }) => id === id?.replace("threshold-", "")
                    ).__typename === "StringVariable" ? (
                    <>
                      <TextField
                        value={comparison}
                        onChange={(event) =>
                          setConditions((conditions) => {
                            const newConditions = [...conditions]; //clone to refresh React state

                            newConditions[
                              conditions.findIndex(
                                ({ localId: idToFind }) => idToFind === localId
                              )
                            ].comparison = event.target
                              .value as FloatComparison;

                            return conditions;
                          })
                        }
                        label={t`Operator`}
                        variant="outlined"
                        select
                        required
                        style={{
                          width: "144px",
                          marginBottom: "24px",
                          marginRight: "16px",
                        }}
                        InputLabelProps={comparison ? { shrink: true } : {}}
                      >
                        <MenuItem
                          style={{
                            color: textOnMainBackgroundColor,
                          }}
                          value="equal"
                          key="equal"
                        >
                          {t`Equal to`}
                        </MenuItem>
                        <MenuItem
                          style={{
                            color: textOnMainBackgroundColor,
                          }}
                          value="notEqual"
                          key="notEqual"
                        >
                          {t`Not equal to`}
                        </MenuItem>
                      </TextField>
                      <TextField
                        label={t`Value`}
                        value={value}
                        variant="outlined"
                        required
                        error={valueEmpty}
                        onChange={(event) =>
                          setConditions((conditions) => {
                            const newConditions = [...conditions]; //clone to refresh React state
                            const conditionIndex: number =
                              newConditions.findIndex(
                                ({ localId: idToFind }) => idToFind === localId
                              );

                            newConditions[conditionIndex].value =
                              event.target.value;

                            newConditions[conditionIndex].valueEmpty =
                              event.target.value.replace(/\s/g, "") === "";

                            return newConditions;
                          })
                        }
                        style={{
                          width: "calc(100% - 160px)",
                        }}
                        InputLabelProps={value ? { shrink: true } : {}}
                      />
                    </>
                  ) : data?.thing.variables.find(
                      ({ id }) => id === id?.replace("threshold-", "")
                    ).__typename === "BooleanVariable" ? (
                    <TextField
                      value={value}
                      onChange={(event) => {
                        setConditions((conditions) => {
                          const newConditions = [...conditions]; //clone to refresh React state

                          newConditions[
                            conditions.findIndex(
                              ({ localId: idToFind }) => idToFind === localId
                            )
                          ].value = event.target.value;

                          return newConditions;
                        });
                      }}
                      label={t`Value`}
                      variant="outlined"
                      select
                      required
                      style={{
                        width: "100%",
                        marginBottom: "24px",
                      }}
                      InputLabelProps={value ? { shrink: true } : {}}
                    >
                      <MenuItem
                        style={{
                          color: textOnMainBackgroundColor,
                        }}
                        value="isTrue"
                        key="isTrue"
                      >
                        {t`True`}
                      </MenuItem>
                      <MenuItem
                        style={{
                          color: textOnMainBackgroundColor,
                        }}
                        value="isFalse"
                        key="isFalse"
                      >
                        {t`False`}
                      </MenuItem>
                    </TextField>
                  ) : data?.thing.variables.find(
                      ({ id }) => id === id?.replace("threshold-", "")
                    ).__typename === "FileVariable" ? (
                    <TextField
                      value={value}
                      onChange={(event) =>
                        setConditions((conditions) => {
                          const newConditions = [...conditions]; //clone to refresh React state

                          newConditions[
                            conditions.findIndex(
                              ({ localId: idToFind }) => idToFind === localId
                            )
                          ].value = event.target.value;
                          return newConditions;
                        })
                      }
                      label={t`Value`}
                      variant="outlined"
                      select
                      required
                      style={{
                        width: "100%",
                        marginBottom: "24px",
                      }}
                      InputLabelProps={value ? { shrink: true } : {}}
                    >
                      <MenuItem
                        style={{
                          color: textOnMainBackgroundColor,
                        }}
                        value="isEmpty"
                        key="isEmpty"
                      >
                        {t`Empty`}
                      </MenuItem>
                      <MenuItem
                        style={{
                          color: textOnMainBackgroundColor,
                        }}
                        value="isNotEmpty"
                        key="isNotEmpty"
                      >
                        {t`Not empty`}
                      </MenuItem>
                    </TextField>
                  ) : null)
                )}
              </Collapse>
            </Collapse>
          )
        )}
        <AndButton
          color="inherit"
          variant="outlined"
          fullWidth
          onClick={() =>
            setConditions((conditions) => [
              ...conditions,
              { localId: Date.now() },
            ])
          }
          style={{ marginBottom: "16px" }}
        >
          {t`And` + "..."}
        </AndButton>
        {actions.map(
          (
            { localId, type, content, contentEmpty, value, valueEmpty },
            index
          ) => (
            <Collapse key={"action-" + localId}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "16px",
                  height: "48px",
                }}
              >
                <Typography
                  variant="h6"
                  style={{
                    width:
                      conditions.length === 1 ? "100%" : "calc(100% - 48px)",
                    margin: 0,
                  }}
                >
                  {index === 0 ? t`Then` : t`And`}
                </Typography>
                {actions.length === 1 ? null : (
                  <IconButton
                    onClick={() =>
                      setActions((actions) =>
                        actions.filter(
                          (_, indexToRemove) => indexToRemove !== index
                        )
                      )
                    }
                    size="large"
                  >
                    <DeleteOutlined />
                  </IconButton>
                )}
              </div>
              <TextField
                value={type}
                onChange={(event) =>
                  setActions((actions) => {
                    const newActions = [...actions]; //clone to refresh React state

                    newActions[
                      actions.findIndex(
                        ({ localId: idToFind }) => idToFind === localId
                      )
                    ].type = event.target.value as ActionType;

                    if (event.target.value === "bilancioIdrico") {
                      newActions[
                        actions.findIndex(
                          ({ localId: idToFind }) => idToFind === localId
                        )
                      ].labParameters = {};
                    } else {
                      delete newActions[
                        actions.findIndex(
                          ({ localId: idToFind }) => idToFind === localId
                        )
                      ].labParameters;
                    }

                    return newActions;
                  })
                }
                label={t`Type`}
                variant="outlined"
                select
                required
                style={{
                  width: "100%",
                  marginBottom: "24px",
                }}
                InputLabelProps={type ? { shrink: true } : {}}
              >
                <MenuItem
                  style={{ color: textOnMainBackgroundColor }}
                  value="sendNotification"
                  key={"create-flow-dialog-action-list-notification"}
                >
                  {t`Send a notification`}
                </MenuItem>
                <MenuItem
                  style={{ color: textOnMainBackgroundColor }}
                  value="bilancioIdrico"
                  key={"create-flow-dialog-action-list-moisture-balance"}
                >
                  {t`Compute moisture balance`}
                </MenuItem>
                <MenuItem
                  style={{ color: textOnMainBackgroundColor }}
                  value="storeOnBlockchain"
                  key={"create-flow-dialog-action-list-store-on-blockchain"}
                >
                  {t`Store on blockchain`}
                </MenuItem>
              </TextField>
              <Collapse
                key={"action-options-" + localId}
                in={type && !type.startsWith("negate-")}
              >
                {type === "bilancioIdrico" ? (
                  <>
                    {Array.from({ length: 12 }, (_, i) => i).map((index) => (
                      <TextField
                        key={`action-water-balance-${localId}-kc-${index}`}
                        value={content}
                        type="number"
                        onChange={(event) =>
                          setActions((actions) => {
                            const newActions = [...actions]; //clone to refresh React state

                            if (
                              Array.isArray(
                                newActions[
                                  actions.findIndex(
                                    ({ localId: idToFind }) =>
                                      idToFind === localId
                                  )
                                ].kc
                              )
                            ) {
                              newActions[
                                actions.findIndex(
                                  ({ localId: idToFind }) =>
                                    idToFind === localId
                                )
                              ].kc[index] = parseFloat(event.target.value);
                            } else {
                              newActions[
                                actions.findIndex(
                                  ({ localId: idToFind }) =>
                                    idToFind === localId
                                )
                              ].kc = [];

                              newActions[
                                actions.findIndex(
                                  ({ localId: idToFind }) =>
                                    idToFind === localId
                                )
                              ].kc[index] = parseFloat(event.target.value);
                            }

                            return newActions;
                          })
                        }
                        error={contentEmpty}
                        label={
                          "KC " +
                          moment()
                            .month(index)
                            .format("MMM")
                            .replace(/^\w/, (firstLetter) =>
                              firstLetter.toUpperCase()
                            )
                        }
                        variant="outlined"
                        required
                        style={{
                          width: "calc((100% - 24px) / 4)",
                          marginRight: !((index + 1) % 4) ? 0 : 8,
                          marginBottom: index > 7 ? 24 : 8,
                        }}
                        InputLabelProps={content ? { shrink: true } : {}}
                      />
                    ))}
                    <TextField
                      key={"action-water-balance-thickness-" + localId}
                      value={content}
                      type="number"
                      onChange={(event) =>
                        setActions((actions) => {
                          const newActions = [...actions]; //clone to refresh React state

                          newActions[
                            actions.findIndex(
                              ({ localId: idToFind }) => idToFind === localId
                            )
                          ].labParameters.spessore = parseFloat(
                            event.target.value
                          );

                          return newActions;
                        })
                      }
                      error={contentEmpty}
                      label={t`Thickness`}
                      variant="outlined"
                      required
                      style={{
                        width: "calc((100% - 8px) / 2)",
                        marginRight: 8,
                        marginBottom: 8,
                      }}
                      InputLabelProps={content ? { shrink: true } : {}}
                    />
                    <TextField
                      key={"action-water-balance-field-capacity-" + localId}
                      value={content}
                      type="number"
                      onChange={(event) =>
                        setActions((actions) => {
                          const newActions = [...actions]; //clone to refresh React state

                          newActions[
                            actions.findIndex(
                              ({ localId: idToFind }) => idToFind === localId
                            )
                          ].labParameters.cc = parseFloat(event.target.value);

                          return newActions;
                        })
                      }
                      error={contentEmpty}
                      label={t`Field capacity`}
                      variant="outlined"
                      required
                      style={{
                        width: "calc((100% - 8px) / 2)",
                        marginBottom: 8,
                      }}
                      InputLabelProps={content ? { shrink: true } : {}}
                    />
                    <TextField
                      key={"action-water-balance-wilting-point-" + localId}
                      value={content}
                      type="number"
                      onChange={(event) =>
                        setActions((actions) => {
                          const newActions = [...actions]; //clone to refresh React state

                          newActions[
                            actions.findIndex(
                              ({ localId: idToFind }) => idToFind === localId
                            )
                          ].labParameters.pa = parseFloat(event.target.value);

                          return newActions;
                        })
                      }
                      error={contentEmpty}
                      label={t`Wilting point`}
                      variant="outlined"
                      required
                      style={{
                        width: "calc((100% - 8px) / 2)",
                        marginRight: 8,
                        marginBottom: 8,
                      }}
                      InputLabelProps={content ? { shrink: true } : {}}
                    />
                    <TextField
                      key={"action-water-balance-density-" + localId}
                      value={content}
                      type="number"
                      onChange={(event) =>
                        setActions((actions) => {
                          const newActions = [...actions]; //clone to refresh React state

                          newActions[
                            actions.findIndex(
                              ({ localId: idToFind }) => idToFind === localId
                            )
                          ].labParameters.densita = parseFloat(
                            event.target.value
                          );

                          return newActions;
                        })
                      }
                      error={contentEmpty}
                      label={t`Density`}
                      variant="outlined"
                      required
                      style={{
                        width: "calc((100% - 8px) / 2)",
                        marginBottom: 8,
                      }}
                      InputLabelProps={content ? { shrink: true } : {}}
                    />
                    <TextField
                      key={"action-water-balance-explored-volume-" + localId}
                      value={content}
                      type="number"
                      onChange={(event) =>
                        setActions((actions) => {
                          const newActions = [...actions]; //clone to refresh React state

                          newActions[
                            actions.findIndex(
                              ({ localId: idToFind }) => idToFind === localId
                            )
                          ].labParameters.volumeEsplorato = parseFloat(
                            event.target.value
                          );

                          return newActions;
                        })
                      }
                      error={contentEmpty}
                      label={t`Explored volume`}
                      variant="outlined"
                      required
                      style={{
                        width: "calc((100% - 8px) / 2)",
                        marginRight: 8,
                        marginBottom: 8,
                      }}
                      InputLabelProps={content ? { shrink: true } : {}}
                    />
                    <TextField
                      key={"action-water-balance-rur-" + localId}
                      value={content}
                      type="number"
                      onChange={(event) =>
                        setActions((actions) => {
                          const newActions = [...actions]; //clone to refresh React state

                          newActions[
                            actions.findIndex(
                              ({ localId: idToFind }) => idToFind === localId
                            )
                          ].labParameters.rfu = parseFloat(event.target.value);

                          return newActions;
                        })
                      }
                      error={contentEmpty}
                      label={t`Readily usable reserve`}
                      variant="outlined"
                      required
                      style={{
                        width: "calc((100% - 8px) / 2)",
                        marginBottom: 8,
                      }}
                      InputLabelProps={content ? { shrink: true } : {}}
                    />
                    <TextField
                      key={"action-water-balance-irrigation-point-" + localId}
                      value={content}
                      type="number"
                      onChange={(event) =>
                        setActions((actions) => {
                          const newActions = [...actions]; //clone to refresh React state

                          newActions[
                            actions.findIndex(
                              ({ localId: idToFind }) => idToFind === localId
                            )
                          ].labParameters.pii = parseFloat(event.target.value);

                          return newActions;
                        })
                      }
                      error={contentEmpty}
                      label={t`Irrigation point`}
                      variant="outlined"
                      required
                      style={{
                        width: "calc((100% - 8px) / 2)",
                        marginRight: 8,
                        marginBottom: 24,
                      }}
                      InputLabelProps={content ? { shrink: true } : {}}
                    />
                    <TextField
                      key={"action-water-balance-ey-" + localId}
                      value={content}
                      type="number"
                      onChange={(event) =>
                        setActions((actions) => {
                          const newActions = [...actions]; //clone to refresh React state

                          newActions[
                            actions.findIndex(
                              ({ localId: idToFind }) => idToFind === localId
                            )
                          ].labParameters.ey = parseFloat(event.target.value);

                          return newActions;
                        })
                      }
                      error={contentEmpty}
                      label={t`Irrigation efficiency`}
                      variant="outlined"
                      required
                      style={{
                        width: "calc((100% - 8px) / 2)",
                        marginBottom: 24,
                      }}
                      InputLabelProps={content ? { shrink: true } : {}}
                    />
                  </>
                ) : type === "sendNotification" ? (
                  <TextField
                    key={"action-notification-text-" + localId}
                    value={content}
                    onChange={(event) =>
                      setActions((actions) => {
                        const newActions = [...actions]; //clone to refresh React state

                        newActions[
                          actions.findIndex(
                            ({ localId: idToFind }) => idToFind === localId
                          )
                        ].content = event.target.value;

                        newActions[
                          conditions.findIndex(
                            ({ localId: idToFind }) => idToFind === localId
                          )
                        ].contentEmpty =
                          event.target.value.replace(/\s/g, "") === "";

                        return newActions;
                      })
                    }
                    error={contentEmpty}
                    label={t`Text`}
                    variant="outlined"
                    required
                    style={{
                      width: "100%",
                      marginBottom: 24,
                    }}
                    InputLabelProps={content ? { shrink: true } : {}}
                  />
                ) : type === "storeOnBlockchain" ? (
                  <FormControl
                    sx={{ m: 3 }}
                    component="fieldset"
                    variant="standard"
                    style={{ margin: 0 }}
                  >
                    <FormLabel component="legend">
                      {t`Select variables to store`}
                    </FormLabel>
                    <FormGroup style={{ marginBottom: 24 }}>
                      {data?.thing.variables.map(({ id, name, __typename }) => (
                        <FormControlLabel
                          key={`store-on-blockchain-checkbox-${id}`}
                          control={<Checkbox />}
                          label={name}
                          onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            setActions((actions) => {
                              const newActions = [...actions]; //clone to refresh React state

                              newActions[
                                actions.findIndex(
                                  ({ localId: idToFind }) =>
                                    idToFind === localId
                                )
                              ][makePluralAndLowercase(__typename)] = event
                                .target.checked
                                ? [
                                    ...(Array.isArray(
                                      newActions[
                                        actions.findIndex(
                                          ({ localId: idToFind }) =>
                                            idToFind === localId
                                        )
                                      ][makePluralAndLowercase(__typename)]
                                    )
                                      ? newActions[
                                          actions.findIndex(
                                            ({ localId: idToFind }) =>
                                              idToFind === localId
                                          )
                                        ][makePluralAndLowercase(__typename)]
                                      : []),
                                    id,
                                  ]
                                : newActions[
                                    actions.findIndex(
                                      ({ localId: idToFind }) =>
                                        idToFind === localId
                                    )
                                  ][makePluralAndLowercase(__typename)].filter(
                                    ({ id: idToRemove }) => idToRemove !== id
                                  );

                              return newActions;
                            })
                          }
                        />
                      ))}
                    </FormGroup>
                  </FormControl>
                ) : type === "bilancioIdrico" ? (
                  <div></div>
                ) : null}
              </Collapse>
            </Collapse>
          )
        )}
        <AndButton
          color="inherit"
          variant="outlined"
          fullWidth
          onClick={() =>
            setActions((actions) => [...actions, { localId: Date.now() }])
          }
          style={{ marginBottom: "16px" }}
        >
          {t`And` + "..."}
        </AndButton>
      </TransitionGroup>
      <Collapse in={showInterval}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "16px",
            height: "48px",
          }}
        >
          <Typography
            variant="h6"
            style={{
              width: conditions.length === 1 ? "100%" : "calc(100% - 48px)",
              margin: 0,
            }}
          >
            {t`At most every`}
          </Typography>
        </div>
        <TextField
          label={t`Duration`}
          type="number"
          value={intervalDuration}
          variant="outlined"
          required
          error={intervalDurationEmpty}
          onChange={(event) => {
            setIntervalDuration(parseFloat(event.target.value));
            setIntervalDurationEmpty(
              event.target.value.replace(/\s/g, "") === ""
            );
          }}
          style={{
            width: "calc(50% - 8px)",
          }}
          InputLabelProps={intervalDuration ? { shrink: true } : {}}
        />
        <TextField
          value={intervalUnit}
          onChange={(event) =>
            setIntervalUnit(event.target.value as IntervalUnit)
          }
          label={t`Unit`}
          variant="outlined"
          select
          required
          style={{
            width: "calc(50% - 8px)",
            marginLeft: "16px",
            marginBottom: "16px",
          }}
          InputLabelProps={intervalUnit ? { shrink: true } : {}}
        >
          <MenuItem
            style={{ color: textOnMainBackgroundColor }}
            value="minutes"
            key="interval-unit-minutes"
          >
            {t`Minutes`}
          </MenuItem>
          <MenuItem
            style={{ color: textOnMainBackgroundColor }}
            value="hours"
            key="interval-unit-hours"
          >
            {t`Hours`}
          </MenuItem>
          <MenuItem
            style={{ color: textOnMainBackgroundColor }}
            value="days"
            key="interval-unit-days"
          >
            {t`Days`}
          </MenuItem>
          <MenuItem
            style={{ color: textOnMainBackgroundColor }}
            value="months"
            key="interval-unit-months"
          >
            {t`Months`}
          </MenuItem>
        </TextField>
      </Collapse>
    </GenericDialog>
  );
};
