import { gql, useMutation, useQuery } from "@apollo/client";
import {
  ErrorScreen,
  GenericDialog,
  CenteredSpinner,
} from "@igloocloud/igloosharedui";
import { Add, Clear, Delete, Download, Edit } from "@mui/icons-material";
import {
  Fab,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { TFunction } from "i18next";
import moment, { Moment } from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Props, { ActivitiesData, CollectionsData } from "./Activities.types";
import { DatePicker } from "@mui/lab";
import Papa from "papaparse";
import { downloadText } from "download.js";

const fetchMoreLoading = false;

const types = [
  { id: "agrenta.aratura", name: "Aratura", unit: "h" },
  { id: "agrenta.araturaADischi", name: "Aratura (a dischi)", unit: "h" },
  { id: "agrenta.assolcatura", name: "Assolcatura", unit: "h" },
  { id: "agrenta.baulatura", name: "Baulatura", unit: "h" },
  { id: "agrenta.diradamentoFrutti", name: "Diradamento frutti", unit: "h" },
  { id: "agrenta.diserbo", name: "Diserbo", unit: "l" },
  { id: "agrenta.dissodamentoScasso", name: "Dissodamento (scasso)" },
  { id: "agrenta.erpicatura", name: "Erpicatura" },
  { id: "agrenta.estirpatura", name: "Estirpatura" },
  { id: "agrenta.fertilizzazione", name: "Fertilizzazione", unit: "kg" },
  { id: "agrenta.fresatura", name: "Fresatura" },
  { id: "agrenta.imballaggio", name: "Imballaggio" },
  { id: "agrenta.irrigazione", name: "Irrigazione", unit: "mm" },
  { id: "agrenta.livellatura", name: "Livellatura" },
  { id: "agrenta.minimaLavorazione", name: "Minima lavorazione" },
  { id: "agrenta.potatura", name: "Potatura" },
  { id: "agrenta.potaturaVerde", name: "Potatura verde" },
  { id: "agrenta.raccolta", name: "Raccolta", unit: "kg" },
  { id: "agrenta.raccoltaBalle", name: "Raccolta balle", unit: "balle" },
  { id: "agrenta.ranghinatura", name: "Ranghinatura" },
  { id: "agrenta.ripuntatura", name: "Ripuntatura" },
  { id: "agrenta.rullatura", name: "Rullatura" },
  { id: "agrenta.sarchiatura", name: "Sarchiatura" },
  { id: "agrenta.scarificatura", name: "Scarificatura" },
  { id: "agrenta.semina", name: "Semina", unit: "kg" },
  { id: "agrenta.seminaConcimazione", name: "Semina e concimazione" },
  { id: "agrenta.sfalcio", name: "Sfalcio" },
  { id: "agrenta.trapianto", name: "Trapianto", unit: "piante" },
  { id: "agrenta.trapiantoConcimazione", name: "Trapianto e concimazione" },
  {
    id: "agrenta.trattamentoFitosanitario",
    name: "Trattamento fitosanitario",
    unit: "l",
  },
  { id: "agrenta.trinciatura", name: "Trinciatura" },
  { id: "agrenta.vangatura", name: "Vangatura" },
  { id: "agrenta.voltaFieno", name: "Volta fieno" },
  { id: "agrenta.zappettatura", name: "Zappettatura" },
];

const cellStyle = {
  whiteSpace: "nowrap",
} as const;

const getReadableDate = (t: TFunction, timestamp: Moment) =>
  timestamp.isSame(moment(), "day")
    ? t`Today`
    : timestamp.isSameOrAfter(moment().subtract(7, "days"), "day") &&
      timestamp.isBefore(new Date())
    ? t`This week`
    : timestamp.isSameOrAfter(moment().subtract(31, "days"), "day") &&
      timestamp.isBefore(new Date())
    ? t`This month`
    : timestamp.fromNow().charAt(0).toUpperCase() +
      timestamp.fromNow().slice(1);

const Activities = ({ mobile }: Props) => {
  const [collectionId, setCollectionId] = useState<string>(null);
  const [type, setType] = useState<string>(types[0].id);
  const [date, setDate] = useState<Moment>(moment());
  const [newActivityOpen, setNewActivityOpen] = useState<boolean>(false);
  const [editActivityOpen, setEditActivityOpen] = useState<boolean>(false);
  const [details, setDetails] = useState<string>("");
  const [hours, setHours] = useState<string>("");
  const [newActivityLoading, setNewActivityLoading] = useState<boolean>(false);
  const [editActivityLoading, setEditActivityLoading] =
    useState<boolean>(false);
  const [activityToEdit, setActivityToEdit] = useState<string>("");

  const skipCollections = !newActivityOpen && !editActivityOpen;

  const {
    error: activitiesError,
    loading: activitiesLoading,
    data: activitiesData,
    refetch: refetchActivities,
    fetchMore: fetchMoreActivities,
    subscribeToMore: subscribeToMoreActivities,
  } = useQuery<ActivitiesData>(
    gql`
      query ($limit: NaturalNumber!, $offset: NaturalNumber) {
        user {
          id
          activityCount
          activities(limit: $limit, offset: $offset) {
            id
            type
            timestamp
            collection {
              id
              name
            }
            metadata
          }
        }
      }
    `,
    {
      variables: {
        limit: 20,
        offset: 0,
      },
    }
  );
  const {
    error: collectionsError,
    loading: collectionsLoading,
    data: collectionsData,
    refetch: refetchCollections,
  } = useQuery<CollectionsData>(
    gql`
      query ($limit: NaturalNumber!, $offset: NaturalNumber) {
        user {
          id
          collectionCount
          collections(limit: $limit, offset: $offset) {
            id
            name
          }
        }
      }
    `,
    {
      skip: skipCollections,
      variables: {
        limit: 20,
        offset: 0,
      },
    }
  );

  const [createActivity] = useMutation(gql`
    mutation createActivity(
      $collectionId: ID!
      $type: String!
      $timestamp: DateTime!
      $metadata: Json
    ) {
      createActivity(
        collectionId: $collectionId
        type: $type
        timestamp: $timestamp
        metadata: $metadata
      ) {
        id
      }
    }
  `);
  const [updateActivity] = useMutation(gql`
    mutation updateActivity(
      $id: ID!
      $type: String!
      $timestamp: DateTime!
      $metadata: Json
    ) {
      updateActivity(
        id: $id
        type: $type
        timestamp: $timestamp
        metadata: $metadata
      ) {
        id
      }
    }
  `);
  const [deleteActivity] = useMutation(gql`
    mutation deleteActivity($id: ID!) {
      deleteActivity(id: $id)
    }
  `);

  subscribeToMoreActivities({
    document: gql`
      subscription {
        activityCreated {
          id
          type
          timestamp
          collection {
            id
            name
          }
          metadata
        }
      }
    `,
    updateQuery: (prev, { subscriptionData }) => {
      if (!subscriptionData.data) return prev;

      const newActivity = (subscriptionData.data as any).activityCreated;

      return {
        ...prev,
        user: {
          ...prev.user,
          activityCount: prev.user.activityCount + 1,
          activities: [
            newActivity,
            ...prev.user.activities.filter(
              ({ id: idToRemove }) => idToRemove !== newActivity.id
            ),
          ],
        },
      };
    },
  });
  subscribeToMoreActivities({
    document: gql`
      subscription {
        activityUpdated {
          id
          type
          timestamp
          collection {
            id
            name
          }
          metadata
        }
      }
    `,
  });
  subscribeToMoreActivities({
    document: gql`
      subscription {
        activityDeleted
      }
    `,
    updateQuery: (prev, { subscriptionData }) => {
      if (!subscriptionData.data) return prev;

      return {
        ...prev,
        user: {
          ...prev.user,
          activityCount: prev.user.activityCount - 1,
          activities: prev.user.activities.filter(
            ({ id: idToRemove }) =>
              idToRemove !== (subscriptionData.data as any).activityDeleted
          ),
        },
      };
    },
  });

  const { t } = useTranslation();
  const {
    REACT_APP_TEXT_ON_MAIN_BACKGROUND_COLOR: textOnMainBackgroundColor,
    REACT_APP_MAIN_BACKGROUND_COLOR: backgroundColor,
    REACT_APP_COLLECTION_NAME: collectionName,
  } = process.env;

  useEffect(() => {
    if (collectionsData?.user.collections[0]) {
      setCollectionId(collectionsData.user.collections[0].id);
    }
  }, [collectionsData]);

  useEffect(() => {
    if (newActivityOpen) {
      refetchCollections({
        limit: 20,
        offset: 0,
        filter: {},
      });

      setCollectionId(null);
      setType(types[0].id);
      setDate(moment());
      setDetails("");
    }
  }, [newActivityOpen]);

  useEffect(() => {
    if (editActivityOpen) {
      setType(
        activitiesData.user.activities.find(
          ({ id: idToFind }) => idToFind === activityToEdit
        ).type
      );
      setDate(
        moment(
          activitiesData.user.activities.find(
            ({ id: idToFind }) => idToFind === activityToEdit
          ).timestamp
        )
      );
      setDetails(
        activitiesData.user.activities.find(
          ({ id: idToFind }) => idToFind === activityToEdit
        ).metadata?.quantity ??
          activitiesData.user.activities.find(
            ({ id: idToFind }) => idToFind === activityToEdit
          ).metadata.details
      );
      setHours(
        activitiesData.user.activities.find(
          ({ id: idToFind }) => idToFind === activityToEdit
        ).metadata?.hours
      );
    }
  }, [editActivityOpen]);

  return (
    <>
      <div
        style={{
          position: "relative",
          height: "calc(100% - 64px)",
          overflow: "hidden",
        }}
      >
        {activitiesLoading ? (
          <CenteredSpinner
            style={{
              paddingTop: "32px",
            }}
          />
        ) : activitiesError ? (
          <ErrorScreen
            refetch={() =>
              refetchActivities({
                limit: 20,
                offset: 0,
              })
            }
            error={activitiesError}
          />
        ) : !activitiesData?.user.activityCount ? (
          <Typography
            variant="h5"
            className="notSelectable defaultCursor"
            style={{
              width: "100%",
              textAlign: "center",
              padding: "32px 0",
              color: textOnMainBackgroundColor,
            }}
          >
            {t`No activities`}
          </Typography>
        ) : (
          <>
            <div
              className="notSelectable"
              style={{
                width: "100%",
                maxWidth: mobile ? "unset" : "768px",
                height: "100%",
                margin: "0 auto",
                overflowY: "auto",
              }}
              onScroll={(event) => {
                const targetAsDiv = event.target as HTMLDivElement;

                if (
                  targetAsDiv.scrollTop + targetAsDiv.clientHeight >=
                  targetAsDiv.scrollHeight - 600
                ) {
                  fetchMoreActivities({
                    variables: {
                      limit: 20,
                      offset: activitiesData.user.activities.length,
                    },
                  });
                }
              }}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow style={{ height: "64px" }}>
                    <TableCell
                      style={{
                        backgroundColor,
                        width: mobile ? "calc(100% / 3)" : "25%",
                      }}
                    >
                      <b>{t`Activity`}</b>
                    </TableCell>
                    {!mobile && (
                      <TableCell
                        style={{
                          backgroundColor,
                          width: "25%",
                        }}
                      >
                        <b>{t(collectionName || "Collection")}</b>
                      </TableCell>
                    )}
                    <TableCell
                      style={{
                        backgroundColor,
                        width: mobile ? "calc(100% / 3)" : "25%",
                      }}
                    >
                      <b>{t`Details`}</b>
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor,
                        width: mobile ? "calc(100% / 3)" : "25%",
                      }}
                    >
                      <b>{t`Date`}</b>
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor,
                        textAlign: "right",
                        padding: "0 8px 0",
                      }}
                    >
                      <IconButton
                        size="large"
                        disabled={!activitiesData.user}
                        onClick={() =>
                          downloadText(
                            "activities.csv",
                            Papa.unparse(
                              JSON.stringify(
                                activitiesData.user.activities.map(
                                  ({
                                    id,
                                    collection,
                                    metadata,
                                    ...others
                                  }) => ({
                                    ...others,
                                    details: metadata.details,
                                    quantity: metadata.quantity,
                                    collection: collection.name,
                                  })
                                )
                              )
                            )
                          )
                        }
                      >
                        <Download />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(activitiesLoading
                    ? new Array(20).fill({})
                    : activitiesData.user.activities
                  ).map(({ id, type, timestamp, collection, metadata }) => (
                    <TableRow key={"activity-" + id} style={{ height: "64px" }}>
                      <TableCell
                        style={{
                          ...cellStyle,
                          width: mobile ? "calc(100% / 3)" : "25%",
                        }}
                      >
                        {activitiesLoading ? (
                          <Skeleton />
                        ) : (
                          types.find(({ id: idToFind }) => idToFind === type)
                            ?.name ?? type
                        )}
                      </TableCell>
                      {!mobile && (
                        <TableCell
                          style={{
                            ...cellStyle,
                            width: "25%",
                          }}
                        >
                          {activitiesLoading ? <Skeleton /> : collection.name}
                        </TableCell>
                      )}
                      <TableCell
                        style={{
                          ...cellStyle,
                          width: mobile ? "calc(100% / 3)" : "25%",
                        }}
                      >
                        {activitiesLoading ? (
                          <Skeleton />
                        ) : types.find(({ id: idToFind }) => idToFind === type)
                            ?.unit ? (
                          `${metadata.quantity ?? 0} ${
                            types.find(({ id: idToFind }) => idToFind === type)
                              .unit
                          }`
                        ) : (
                          metadata.details
                        )}
                      </TableCell>
                      <TableCell
                        style={{
                          ...cellStyle,
                          width: mobile ? "calc(100% / 3)" : "25%",
                        }}
                      >
                        {activitiesLoading ? (
                          <Skeleton />
                        ) : (
                          getReadableDate(t, moment(timestamp))
                        )}
                      </TableCell>
                      <TableCell
                        style={{
                          ...cellStyle,
                          width: "96px",
                          padding: "0 8px",
                        }}
                        align="right"
                      >
                        {activitiesLoading ? (
                          <Skeleton
                            variant="circular"
                            style={{
                              width: "24px",
                              height: "24px",
                              margin: "auto",
                            }}
                          />
                        ) : (
                          <IconButton
                            onClick={() => {
                              setEditActivityOpen(true);
                              setActivityToEdit(id);
                            }}
                            size="large"
                          >
                            <Edit />
                          </IconButton>
                        )}
                        {activitiesLoading ? (
                          <Skeleton
                            variant="circular"
                            style={{
                              width: "24px",
                              height: "24px",
                              margin: "auto",
                            }}
                          />
                        ) : (
                          <IconButton
                            onClick={() => {
                              deleteActivity({
                                variables: {
                                  id,
                                },
                              });
                            }}
                            size="large"
                          >
                            <Delete />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
            {fetchMoreLoading && (
              <LinearProgress
                style={
                  mobile
                    ? {
                        position: "absolute",
                        top: 0,
                        width: "100%",
                        maxWidth: "unset",
                        margin: "0 auto",
                        zIndex: 100,
                      }
                    : {
                        margin: "-4px auto 0 auto",
                        width: "100%",
                        maxWidth: "768px",
                      }
                }
              />
            )}
          </>
        )}
        <Fab
          variant="extended"
          color="secondary"
          style={{ position: "absolute", right: 24, bottom: 24 }}
          onClick={() => setNewActivityOpen(true)}
        >
          <Add
            style={{
              marginRight: "8px",
            }}
          />
          {t`Activity`}
        </Fab>
      </div>
      <GenericDialog
        open={newActivityOpen}
        close={() => setNewActivityOpen(false)}
        title={t`New activity`}
        textButton={t`Close`}
        textButtonFunction={() => setNewActivityOpen(false)}
        containedButton={t`Create`}
        containedButtonFunction={() => {
          setNewActivityLoading(true);

          createActivity({
            variables: {
              collectionId,
              type,
              timestamp: date,
              metadata: types.find(({ id: idToFind }) => idToFind === type).unit
                ? { quantity: details, hours }
                : { details, hours },
            },
          })
            .then(() => setNewActivityOpen(false))
            .finally(() => setNewActivityLoading(false));
        }}
        containedButtonDisabled={!collectionId || !type || !date || !details}
        containedButtonLoading={newActivityLoading}
      >
        {!skipCollections &&
          (collectionsLoading ? (
            <CenteredSpinner
              style={{
                paddingTop: "32px",
              }}
            />
          ) : collectionsError ? (
            <ErrorScreen
              refetch={() =>
                refetchCollections({
                  limit: 20,
                  offset: 0,
                  filter: {},
                })
              }
              error={collectionsError}
            />
          ) : (
            <>
              <FormControl
                fullWidth
                style={{
                  marginBottom: 24,
                }}
              >
                <InputLabel shrink>
                  {t(collectionName || `Collection`)}
                </InputLabel>
                <Select
                  value={collectionId}
                  label={t(collectionName || `Collection`)}
                  onChange={(event) => setCollectionId(event.target.value)}
                >
                  {collectionsData.user.collections.map(({ id, name }) => (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                fullWidth
                style={{
                  marginBottom: 24,
                }}
              >
                <InputLabel shrink>{t`Type`}</InputLabel>
                <Select
                  value={type}
                  label={t`Type`}
                  onChange={(event) => setType(event.target.value)}
                >
                  {types.map(({ id, name }) => (
                    <MenuItem key={"new-activity-types-" + type} value={id}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <DatePicker
                onChange={(newValue) => setDate(newValue)}
                value={date}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    style={{ marginBottom: 24 }}
                    label={t`Date`}
                  />
                )}
              />
              {types.find(({ id: idToFind }) => idToFind === type).unit !==
                "h" && (
                <TextField
                  label={t`Hours`}
                  type="number"
                  required
                  value={hours}
                  variant="outlined"
                  onChange={(event) => setHours(event.target.value)}
                  onKeyPress={(event) => {
                    if (
                      event.key === "Enter" &&
                      collectionId &&
                      type &&
                      date &&
                      details
                    ) {
                      createActivity({
                        variables: {
                          collectionId,
                          type,
                          timestamp: date,
                          metadata: types.find(
                            ({ id: idToFind }) => idToFind === type
                          ).unit
                            ? { quantity: details, hours }
                            : { details, hours },
                        },
                      })
                        .then(() => setNewActivityOpen(false))
                        .finally(() => setNewActivityLoading(false));
                    }
                  }}
                  style={{
                    width: "100%",
                    marginBottom: 24,
                  }}
                  InputLabelProps={details ? { shrink: true } : {}}
                />
              )}
              {types.find(({ id: idToFind }) => idToFind === type).unit ? (
                <TextField
                  label={t`Quantity`}
                  type="number"
                  required
                  value={details}
                  variant="outlined"
                  onChange={(event) => setDetails(event.target.value)}
                  onKeyPress={(event) => {
                    if (
                      event.key === "Enter" &&
                      collectionId &&
                      type &&
                      date &&
                      details
                    ) {
                      createActivity({
                        variables: {
                          collectionId,
                          type,
                          timestamp: date,
                          metadata: types.find(
                            ({ id: idToFind }) => idToFind === type
                          ).unit
                            ? { quantity: details, hours }
                            : { details, hours },
                        },
                      })
                        .then(() => setNewActivityOpen(false))
                        .finally(() => setNewActivityLoading(false));
                    }
                  }}
                  style={{
                    width: "100%",
                  }}
                  InputLabelProps={details ? { shrink: true } : {}}
                  InputProps={{
                    endAdornment: types.find(
                      ({ id: idToFind }) => idToFind === type
                    ).unit,
                  }}
                />
              ) : (
                <TextField
                  label={t`Details`}
                  required
                  value={details}
                  variant="outlined"
                  onChange={(event) => setDetails(event.target.value)}
                  onKeyPress={(event) => {
                    if (
                      event.key === "Enter" &&
                      collectionId &&
                      type &&
                      date &&
                      details
                    ) {
                      createActivity({
                        variables: {
                          collectionId,
                          type,
                          timestamp: date,
                          metadata: types.find(
                            ({ id: idToFind }) => idToFind === type
                          ).unit
                            ? { quantity: details, hours }
                            : { details, hours },
                        },
                      })
                        .then(() => setNewActivityOpen(false))
                        .finally(() => setNewActivityLoading(false));
                    }
                  }}
                  style={{
                    width: "100%",
                  }}
                  InputLabelProps={details ? { shrink: true } : {}}
                  InputProps={{
                    endAdornment: details && (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setDetails("")} size="large">
                          <Clear />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </>
          ))}
      </GenericDialog>
      <GenericDialog
        open={editActivityOpen}
        close={() => setEditActivityOpen(false)}
        title={t`Edit activity`}
        textButton={t`Close`}
        textButtonFunction={() => setEditActivityOpen(false)}
        containedButton={t`Edit`}
        containedButtonFunction={() => {
          setEditActivityLoading(true);

          updateActivity({
            variables: {
              id: activityToEdit,
              type,
              timestamp: date,
              metadata: types.find(({ id: idToFind }) => idToFind === type).unit
                ? { quantity: details }
                : { details },
            },
          })
            .then(() => setEditActivityOpen(false))
            .finally(() => setEditActivityLoading(false));
        }}
        containedButtonDisabled={!type || !date || !details}
        containedButtonLoading={editActivityLoading}
      >
        {!skipCollections &&
          (collectionsLoading ? (
            <CenteredSpinner
              style={{
                paddingTop: "32px",
              }}
            />
          ) : collectionsError ? (
            <ErrorScreen
              refetch={() =>
                refetchCollections({
                  limit: 20,
                  offset: 0,
                  filter: {},
                })
              }
              error={collectionsError}
            />
          ) : (
            <>
              <FormControl
                fullWidth
                style={{
                  marginBottom: 24,
                }}
              >
                <InputLabel shrink>{t`Type`}</InputLabel>
                <Select
                  value={type}
                  label={t`Type`}
                  onChange={(event) => setType(event.target.value)}
                >
                  {types.map(({ id, name }) => (
                    <MenuItem key={"new-activity-types-" + type} value={id}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <DatePicker
                onChange={(newValue) => setDate(newValue)}
                value={date}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    style={{ marginBottom: 24 }}
                    label={t`Date`}
                  />
                )}
              />
              {types.find(({ id: idToFind }) => idToFind === type).unit ? (
                <TextField
                  label={t`Quantity`}
                  type="number"
                  required
                  value={details}
                  variant="outlined"
                  onChange={(event) => setDetails(event.target.value)}
                  onKeyPress={(event) => {
                    if (event.key === "Enter" && type && date && details) {
                      updateActivity({
                        variables: {
                          id: activityToEdit,
                          type,
                          timestamp: date,
                          metadata: types.find(
                            ({ id: idToFind }) => idToFind === type
                          ).unit
                            ? { quantity: details }
                            : { details },
                        },
                      })
                        .then(() => setEditActivityOpen(false))
                        .finally(() => setEditActivityLoading(false));
                    }
                  }}
                  style={{
                    width: "100%",
                  }}
                  InputLabelProps={details ? { shrink: true } : {}}
                  InputProps={{
                    endAdornment: types.find(
                      ({ id: idToFind }) => idToFind === type
                    ).unit,
                  }}
                />
              ) : (
                <TextField
                  label={t`Details`}
                  required
                  value={details}
                  variant="outlined"
                  onChange={(event) => setDetails(event.target.value)}
                  onKeyPress={(event) => {
                    if (event.key === "Enter" && type && date && details) {
                      updateActivity({
                        variables: {
                          id: activityToEdit,
                          type,
                          timestamp: date,
                          metadata: types.find(
                            ({ id: idToFind }) => idToFind === type
                          ).unit
                            ? { quantity: details }
                            : { details },
                        },
                      })
                        .then(() => setEditActivityOpen(false))
                        .finally(() => setEditActivityLoading(false));
                    }
                  }}
                  style={{
                    width: "100%",
                  }}
                  InputLabelProps={details ? { shrink: true } : {}}
                  InputProps={{
                    endAdornment: details && (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setDetails("")} size="large">
                          <Clear />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </>
          ))}
      </GenericDialog>
    </>
  );
};

export default Activities;
