import React, { Component } from "react";
import CollectionFetcher from "./CollectionFetcher";
import { graphql } from "@apollo/react-hoc";
import gql from "graphql-tag";
import { Switch, Route, Redirect } from "react-router-dom";
import IsOnline from "is-online-component";
import {
  Error404,
  EmailNotVerified,
  PolicyDialog,
} from "@igloocloud/igloosharedui";
import Collections from "./Collections";
import querystringify from "querystringify";
import moment from "moment";
import { withTranslation } from "react-i18next";

const appName = process.env.REACT_APP_NAME;

export default graphql(
  gql`
    mutation ChangeLanguage($language: String) {
      updateUser(language: $language) {
        language
      }
    }
  `,
  {
    name: "ChangeLanguage",
  }
)(
  graphql(
    gql`
      query (
        $limit: NaturalNumber!
        $offset: NaturalNumber
        $filter: CollectionFilterExtended
      ) {
        metadata {
          privacyPolicyVersion
        }
        user {
          id
          emailIsVerified
          name
          profileIconColor
          email
          collectionCount
          pendingShareCount
          pendingTransferCount
          timeFormat
          dateFormat
          lengthAndMass
          temperature
          appNotifications
          smsNotifications
          emailNotifications
          passwordChangeNotification
          accessTokenCreatedNotification
          shareAndTransferNotification
          reportNotification
          totpEnabled
          privacyPolicyAccepted
          firstDayOfTheWeek
          phoneVerified
          phoneNumber
          flags {
            id
            reportSendingEnabled
            smsActive
          }
          collections(
            sortBy: name
            sortDirection: ASCENDING
            limit: $limit
            offset: $offset
            filter: $filter
          ) {
            id
            index
            name
            createdAt
            updatedAt
            muted
            picture
            myRole
            editorCount
            viewerCount
            thingCount
            geometries(
              limit: 1
              minLat: -90
              maxLat: 90
              minLon: -180
              maxLon: 180
            ) {
              id
            }
          }
        }
      }
    `,
    {
      name: "userData",
      options: {
        variables: {
          limit: 20,
          offset: 0,
          filter: {},
        },
      },
    }
  )(
    withTranslation()(
      class UserFetcher extends Component {
        state = {
          collectionsSearchText: "",
          thingsSearchText: "",
          snackbarOpen: true,
          acceptPrivacyPolicyOpen: false,
        };

        changeLanguage = (language) => {
          this.props.ChangeLanguage({
            variables: {
              language,
            },
            optimisticResponse: {
              __typename: "Mutation",
              user: {
                language,
                __typename: "User",
              },
            },
          });
        };

        componentDidMount() {
          this.changeLanguage(
            localStorage.getItem("language") ||
              (navigator.language || navigator.userLanguage).slice(0, 2)
          );

          this.props.userData.subscribeToMore({
            document: gql`
              subscription {
                collectionCreated {
                  id
                  index
                  name
                  createdAt
                  updatedAt
                  muted
                  picture
                  myRole
                  editorCount
                  viewerCount
                  thingCount
                }
              }
            `,
            updateQuery: (prev, { subscriptionData }) => {
              if (!subscriptionData.data) {
                return prev;
              }

              const newCollections = [
                ...prev.user.collections,
                subscriptionData.data.collectionCreated,
              ].sort((a, b) =>
                a.name.toLowerCase() > b.name.toLowerCase()
                  ? 1
                  : a.name.toLowerCase() < b.name.toLowerCase()
                  ? -1
                  : 0
              );

              return {
                user: {
                  ...prev.user,
                  collections: newCollections,
                },
              };
            },
          });

          this.props.userData.subscribeToMore({
            document: gql`
              subscription {
                collectionUpdated {
                  id
                  index
                  name
                  createdAt
                  updatedAt
                  muted
                  picture
                  myRole
                  editorCount
                  viewerCount
                  thingCount
                  uniqueFirmwares
                }
              }
            `,
            updateQuery: (prev, { subscriptionData }) => {
              if (!subscriptionData.data) {
                return prev;
              }

              const newCollections = [
                ...prev.user.collections.filter(
                  (collection) =>
                    collection.id !== subscriptionData.data.collectionUpdated.id
                ),
                subscriptionData.data.collectionUpdated,
              ].sort((a, b) =>
                a.name.toLowerCase() > b.name.toLowerCase()
                  ? 1
                  : a.name.toLowerCase() < b.name.toLowerCase()
                  ? -1
                  : 0
              );

              return {
                user: {
                  ...prev.user,
                  collections: newCollections,
                },
              };
            },
          });

          const collectionDeletedSubscription = gql`
            subscription {
              collectionDeleted
            }
          `;

          this.props.userData.subscribeToMore({
            document: collectionDeletedSubscription,
            updateQuery: (prev, { subscriptionData }) => {
              if (!subscriptionData.data) {
                return prev;
              }

              const newCollections = prev.user.collections.filter(
                (collection) =>
                  collection.id !== subscriptionData.data.collectionDeleted
              );

              return {
                user: {
                  ...prev.user,
                  collections: newCollections,
                },
              };
            },
          });

          this.props.userData.subscribeToMore({
            document: gql`
              subscription {
                userLeftCollection {
                  user {
                    id
                  }
                  collection {
                    id
                  }
                }
              }
            `,
            updateQuery: (prev, { subscriptionData }) => {
              if (!subscriptionData.data) {
                return prev;
              }

              if (
                subscriptionData.data.userLeftCollection.user.id !==
                localStorage.getItem("userId")
              ) {
                return prev;
              }

              const newCollections = prev.user.collections.filter(
                (collection) =>
                  collection.id !==
                  subscriptionData.data.userLeftCollection.collection.id
              );

              return {
                user: {
                  ...prev.user,
                  collections: newCollections,
                },
              };
            },
          });

          this.props.userData.subscribeToMore({
            document: gql`
              subscription {
                userUpdated {
                  id
                  emailIsVerified
                  name
                  profileIconColor
                  email
                  collectionCount
                  pendingShareCount
                  pendingTransferCount
                  timeFormat
                  dateFormat
                  lengthAndMass
                  temperature
                  appNotifications
                  smsNotifications
                  emailNotifications
                  passwordChangeNotification
                  accessTokenCreatedNotification
                  shareAndTransferNotification
                  reportNotification
                  totpEnabled
                  language
                  privacyPolicyAccepted
                  firstDayOfTheWeek
                  phoneVerified
                  phoneNumber
                  flags {
                    id
                    reportSendingEnabled
                    smsActive
                  }
                }
              }
            `,
          });

          const userDeletedSubscription = gql`
            subscription {
              userDeleted
            }
          `;

          this.props.userData.subscribeToMore({
            document: userDeletedSubscription,
            updateQuery: () => {
              this.props.logOut(true);
            },
          });

          const pendingShareAcceptedSubscription = gql`
            subscription {
              pendingShareAccepted {
                id
                recipient {
                  id
                  profileIconColor
                  name
                  email
                }
                sender {
                  id
                  name
                }
                collection {
                  id
                  index
                  name
                  createdAt
                  updatedAt
                  muted
                  picture
                  myRole
                  editorCount
                  viewerCount
                  thingCount
                }
              }
            }
          `;

          this.props.userData.subscribeToMore({
            document: pendingShareAcceptedSubscription,
            updateQuery: (prev, { subscriptionData }) => {
              if (!subscriptionData.data) {
                return prev;
              }

              const collections = [
                ...prev.user.collections.filter(
                  (collection) =>
                    collection.id !==
                    subscriptionData.data.pendingShareAccepted.collection.id
                ),
                subscriptionData.data.pendingShareAccepted.collection,
              ].sort((a, b) =>
                a.name.toLowerCase() > b.name.toLowerCase()
                  ? 1
                  : a.name.toLowerCase() < b.name.toLowerCase()
                  ? -1
                  : 0
              );

              return {
                user: {
                  ...prev.user,
                  collections,
                },
              };
            },
          });

          const pendingTransferAcceptedSubscription = gql`
            subscription {
              pendingTransferAccepted {
                id
                recipient {
                  id
                  profileIconColor
                  name
                  email
                }
                sender {
                  id
                  name
                }
                collection {
                  id
                  index
                  name
                  createdAt
                  updatedAt
                  muted
                  picture
                  myRole
                  editorCount
                  viewerCount
                  thingCount
                }
              }
            }
          `;

          this.props.userData.subscribeToMore({
            document: pendingTransferAcceptedSubscription,
            updateQuery: (prev, { subscriptionData }) => {
              if (!subscriptionData.data) {
                return prev;
              }

              const collections = [
                ...prev.user.collections.filter(
                  (collection) =>
                    collection.id !==
                    subscriptionData.data.pendingTransferAccepted.collection.id
                ),
                subscriptionData.data.pendingTransferAccepted.collection,
              ].sort((a, b) =>
                a.name.toLowerCase() > b.name.toLowerCase()
                  ? 1
                  : a.name.toLowerCase() < b.name.toLowerCase()
                  ? -1
                  : 0
              );

              return {
                user: {
                  ...prev.user,
                  collections,
                },
              };
            },
          });

          if (this.props.userData.user)
            this.props.userData.subscribeToMore({
              document: gql`
                subscription ($userId: ID!) {
                  pendingShareDeleted(userId: $userId) {
                    collection {
                      id
                    }
                  }
                }
              `,
              variables: { userId: localStorage.getItem("userId") },
              updateQuery: (prev, { subscriptionData }) => {
                if (!subscriptionData.data) {
                  return prev;
                }

                const collections = prev.user.collections.filter(
                  (collection) =>
                    collection.id !==
                    subscriptionData.data.pendingShareDeleted.collection.id
                );

                return {
                  user: {
                    ...prev.user,
                    collections,
                  },
                };
              },
            });
        }

        componentWillReceiveProps(nextProps) {
          if (
            !this.props.userData.user &&
            nextProps.userData.user &&
            nextProps.userData.metadata &&
            nextProps.userData.user.privacyPolicyAccepted !==
              nextProps.userData.metadata.privacyPolicyVersion
          ) {
            this.setState({ acceptPrivacyPolicyOpen: true });
          }

          if (!this.props.userData.user && nextProps.userData.user) {
            moment.updateLocale(nextProps.i18n.language, {
              week: {
                dow:
                  nextProps.userData.user.firstDayOfTheWeek === "FRIDAY"
                    ? 5
                    : nextProps.userData.user.firstDayOfTheWeek === "SATURDAY"
                    ? 6
                    : nextProps.userData.user.firstDayOfTheWeek === "SUNDAY"
                    ? 7
                    : 1,
              },
            });
          }
        }

        render() {
          const {
              userData: { user },
              logOut,
            } = this.props,
            { acceptPrivacyPolicyOpen } = this.state;
          const {
            REACT_APP_ENABLE_MAPS: enableMaps,
            REACT_APP_ENABLE_DATA_LAB: enableDataLab,
            REACT_APP_ENABLE_REPORTS: enableReports,
            REACT_APP_ENABLE_ACTIVITIES: enableActivities,
          } = process.env;

          return (
            <>
              <IsOnline
                onChange={(online) => {
                  if (online) {
                    this.props.userData.refetch();
                  }
                }}
              />
              <PolicyDialog
                requireConsent
                open={acceptPrivacyPolicyOpen}
                close={() => this.setState({ acceptPrivacyPolicyOpen: false })}
                user={user}
              />
              <Switch>
                <Route
                  exact
                  path={[
                    "/",
                    ...(enableReports ? ["/reports"] : []),
                    ...(enableDataLab ? ["/data"] : []),
                    ...(enableMaps ? ["/maps"] : []),
                    ...(enableActivities ? ["/activities"] : []),
                  ]}
                >
                  {querystringify.parse(
                    window.cordova &&
                      window.location.hash.split("?").length === 2
                      ? "?" + window.location.hash.split("?")[1]
                      : window.location.search
                  ).collection ||
                  querystringify.parse(
                    window.cordova &&
                      window.location.hash.split("?").length === 2
                      ? "?" + window.location.hash.split("?")[1]
                      : window.location.search
                  ).thing ? (
                    querystringify.parse(
                      window.cordova &&
                        window.location.hash.split("?").length === 2
                        ? "?" + window.location.hash.split("?")[1]
                        : window.location.search
                    ).thing ? (
                      <>
                        <CollectionFetcher
                          mobile={this.props.isMobile}
                          logOut={logOut}
                          changeAccount={this.props.changeAccount}
                          userData={this.props.userData}
                          selectedThing={
                            querystringify.parse(
                              window.cordova &&
                                window.location.hash.split("?").length === 2
                                ? "?" + window.location.hash.split("?")[1]
                                : window.location.search
                            ).thing
                          }
                          collectionId={
                            querystringify.parse(
                              window.cordova &&
                                window.location.hash.split("?").length === 2
                                ? "?" + window.location.hash.split("?")[1]
                                : window.location.search
                            ).collection
                          }
                          collections={
                            this.props.userData.user &&
                            this.props.userData.user.collections
                          }
                          searchThings={(text) => {
                            this.setState({ thingsSearchText: text });
                          }}
                          thingsSearchText={this.state.thingsSearchText}
                          forceUpdate={this.props.forceUpdate}
                          client={this.props.client}
                        />
                        <EmailNotVerified
                          open={
                            user &&
                            !user.emailIsVerified &&
                            this.state.snackbarOpen
                          }
                          close={() => this.setState({ snackbarOpen: false })}
                          user={this.props.userData.user}
                        />
                      </>
                    ) : (
                      <>
                        <CollectionFetcher
                          mobile={this.props.isMobile}
                          logOut={this.props.logOut}
                          changeAccount={this.props.changeAccount}
                          userData={this.props.userData}
                          selectedThing={null}
                          collectionId={
                            querystringify.parse(
                              window.cordova &&
                                window.location.hash.split("?").length === 2
                                ? "?" + window.location.hash.split("?")[1]
                                : window.location.search
                            ).collection
                          }
                          collections={
                            this.props.userData.user &&
                            this.props.userData.user.collections
                          }
                          searchThings={(text) => {
                            this.setState({ thingsSearchText: text });
                          }}
                          thingsSearchText={this.state.thingsSearchText}
                          forceUpdate={this.props.forceUpdate}
                          client={this.props.client}
                        />
                        <EmailNotVerified
                          mobile={this.props.isMobile}
                          open={
                            user &&
                            !user.emailIsVerified &&
                            this.state.snackbarOpen
                          }
                          close={() => this.setState({ snackbarOpen: false })}
                          email={user?.email}
                          user={this.props.userData.user}
                        />
                      </>
                    )
                  ) : (
                    <>
                      <Collections
                        userData={this.props.userData}
                        logOut={this.props.logOut}
                        changeAccount={this.props.changeAccount}
                        changeBearer={this.props.changeBearer}
                        searchCollections={(text) => {
                          this.setState({ collectionsSearchText: text });
                        }}
                        collectionsSearchText={this.state.collectionsSearchText}
                        forceUpdate={this.props.forceUpdate}
                        client={this.props.client}
                        mobile={this.props.isMobile}
                        changeEmail={this.props.changeEmail}
                        changeEmailBearer={this.props.changeEmailBearer}
                        changeAuthenticationBearer={
                          this.props.changeAuthenticationBearer
                        }
                        deleteUserBearer={this.props.deleteUserBearer}
                        manageAccessTokensBearer={
                          this.props.manageAccessTokensBearer
                        }
                      />
                      <EmailNotVerified
                        mobile={this.props.isMobile}
                        open={
                          user &&
                          !user.emailIsVerified &&
                          this.state.snackbarOpen
                        }
                        close={() => this.setState({ snackbarOpen: false })}
                        email={user?.email}
                        user={this.props.userData.user}
                      />
                    </>
                  )}
                </Route>
                <Route>
                  <Error404
                    isMobile={this.props.isMobile}
                    setRedirect={(redirectTo) => this.setState({ redirectTo })}
                    appName={appName}
                  />
                </Route>
              </Switch>
              {this.state.redirectTo && (
                <Redirect push to={this.state.redirectTo} />
              )}
            </>
          );
        }
      }
    )
  )
);
