import { graphql } from "@apollo/react-hoc";
import { getNotchHeight, MainBody } from "@igloocloud/igloosharedui";
import AppBar from "@mui/material/AppBar";
import gql from "graphql-tag";
import IsOnline from "is-online-component";
import { Component } from "react";
import { Redirect } from "react-router-dom";
import MainBodyHeader from "./components/MainBodyHeader";
import backgroundLogo from "./styles/assets/background-logo.svg";

export default graphql(
  gql`
    query ($id: ID!, $offset: NaturalNumber, $limit: NaturalNumber!) {
      thing(id: $id) {
        id
        lat
        lon
        angle
        pairCode
        name
        online
        collection {
          id
        }
        muted
        type
        firmware
        starred
        updatedAt
        createdAt
        myRole
        qrCode
        notificationCount(filter: { read: false })
        variableCount(filter: { hidden: false })
        hiddenVariableCount: variableCount(filter: { hidden: true })
        variables(
          limit: $limit
          offset: $offset
          sortBy: index
          filter: { hidden: false }
        ) {
          id
          index
          hidden
          name
          updatedAt
          createdAt
          myRole
          editableBy
          thing {
            id
          }
          ... on FloatVariable {
            floatValue: value
            precision
            min
            max
            unitOfMeasurement
            visualization
            floatAllowedValues: allowedValues
          }
          ... on StringVariable {
            stringVariable: value
            maxCharacters
            stringAllowedValues: allowedValues
            visualization
          }
          ... on BooleanVariable {
            boolValue: value
            visualization
          }
          ... on ImpulseVariable {
            impulseValue: value
            visualization
            content
          }
          ... on FileVariable {
            fileValue: value
            fileName
            visualization
            maxSize
          }
          ... on LayoutVariable {
            floorCount
            visualization
            maxSize
          }
          ... on FloatSeriesVariable {
            min
            max
            minThreshold
            maxThreshold
            precision
            shownNodes
            storedNodes
            nodeCount
            visualization
            lastNode {
              id
            }
          }
          ... on MapVariable {
            markers {
              lat
              lon
              angle
              icon
            }
          }
        }
      }
    }
  `,
  {
    name: "thingData",
    options: ({ thingId }) => ({
      variables: {
        id: thingId,
        offset: 0,
        limit: 20,
      },
    }),
  }
)(
  class ThingFetcher extends Component {
    state = {
      redirectTo: "",
    };

    render() {
      const {
        userData,
        collectionData,
        thingData,
        drawer,
        changeDrawerState,
        hiddenNotifications,
        showHiddenNotifications,
        collections,
        isMobile,
        selectedThing,
        client,
        openSnackBar,
        thingId,
      } = this.props;
      const { redirectTo } = this.state;

      if (redirectTo) {
        const tempRedirect = redirectTo;
        this.setState({ redirectTo: "" });

        return <Redirect to={tempRedirect} />;
      }

      return (
        <>
          <IsOnline
            onChange={(online) => {
              if (online) {
                this.props.thingData.refetch();
              }
            }}
          />
          {isMobile ? (
            <div
              style={{
                width: "100%",
                height: "100%",
                backgroundImage: "unset",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            >
              <MainBody
                collectionData={collectionData}
                thingData={thingData}
                setRedirect={(redirectTo) => this.setState({ redirectTo })}
                mobile
                thingId={thingId}
              />
              <AppBar
                position="sticky"
                style={{
                  boxShadow:
                    "0px -2px 4px -1px rgba(0,0,0,0.2), 0px -4px 5px 0px rgba(0,0,0,0.14), 0px -1px 10px 0px rgba(0,0,0,0.12)",
                }}
              >
                <MainBodyHeader
                  key="mainBodyHeader"
                  drawer={drawer}
                  changeDrawerState={changeDrawerState}
                  hiddenNotifications={hiddenNotifications}
                  showHiddenNotifications={showHiddenNotifications}
                  collectionData={collectionData}
                  collections={collections}
                  isMobile={isMobile}
                  userData={userData}
                  client={client}
                  data={thingData}
                  thingId={thingId}
                />
              </AppBar>
            </div>
          ) : (
            <>
              {selectedThing !== null ? (
                <MainBodyHeader
                  thingId={thingId}
                  key="mainBodyHeader"
                  drawer={drawer}
                  changeDrawerState={changeDrawerState}
                  hiddenNotifications={hiddenNotifications}
                  showHiddenNotifications={showHiddenNotifications}
                  openSnackBar={openSnackBar}
                  collectionData={collectionData}
                  collections={collections}
                  userData={userData}
                  client={client}
                  data={thingData}
                />
              ) : (
                <div
                  style={{
                    gridArea: "mainBodyHeader",
                    backgroundColor: process.env.REACT_APP_LIGHT_COLOR,
                    paddingTop: getNotchHeight("top"),
                  }}
                  key="mainBodyHeader"
                />
              )}
              {selectedThing !== null ? (
                <>
                  <MainBody
                    collectionData={collectionData}
                    userData={userData}
                    thingData={thingData}
                    setRedirect={(redirectTo) => this.setState({ redirectTo })}
                    thingId={thingId}
                  />
                </>
              ) : (
                <>
                  <div
                    style={{
                      background: process.env.REACT_APP_MAIN_BACKGROUND_COLOR,
                    }}
                    className="mainBody"
                  >
                    <div
                      className="mainBodyBG"
                      style={{
                        backgroundImage: "url(" + backgroundLogo + ")",
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  </div>
                </>
              )}
            </>
          )}
        </>
      );
    }
  }
);
