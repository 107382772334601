import React, { Component } from "react";
import Skeleton from "@mui/material/Skeleton";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import IconButton from "@mui/material/IconButton";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";
import Badge from "@mui/material/Badge";
import LinearProgress from "@mui/material/LinearProgress";
import { withStyles } from "@mui/styles";
import moment from "moment";
import { withTranslation } from "react-i18next";
import { graphql } from "@apollo/react-hoc";
import gql from "graphql-tag";
import { ErrorScreen } from "@igloocloud/igloosharedui";
import { Fab } from "@mui/material";
import { Add } from "@mui/icons-material";

const {
  REACT_APP_MAIN_BACKGROUND_COLOR: backgroundColor,
  REACT_APP_TEXT_ON_MAIN_BACKGROUND_COLOR: textOnMainBackgroundColor,
  REACT_APP_COLLECTION_NAME: collectionName,
} = process.env;

const StyledBadge = withStyles(() => ({
  badge: {
    right: 4,
    top: 4,
  },
}))(Badge);

const cellStyle = {
  whiteSpace: "nowrap",
};

export default graphql(
  gql`
    query ($offset: NaturalNumber, $limit: NaturalNumber!) {
      user {
        id
        reportCount
        reports(limit: $limit, offset: $offset) {
          id
          createdAt
          downloaded
          downloadUrl
          title
          collection {
            id
            name
          }
          producer {
            id
            name
          }
        }
      }
    }
  `,
  {
    name: "userData",
    options: () => ({
      variables: {
        offset: 0,
        limit: 20,
      },
    }),
  }
)(
  graphql(
    gql`
      mutation setDownloaded($id: ID!) {
        updateReport(id: $id, downloaded: true) {
          id
          downloaded
        }
      }
    `
  )(
    withTranslation()(
      class Reports extends Component {
        state = {
          fetchMoreLoading: false,
        };

        componentDidMount() {
          this.props.userData.subscribeToMore({
            document: gql`
              subscription {
                reportCreated {
                  id
                  createdAt
                  downloaded
                  downloadUrl
                  title
                  collection {
                    id
                    name
                  }
                  producer {
                    id
                    name
                  }
                }
              }
            `,
            updateQuery: (prev, { subscriptionData }) => {
              if (!subscriptionData.data) {
                return prev;
              }

              const newReports = [
                ...prev.user.reports,
                subscriptionData.data.reportCreated,
              ].sort((a, b) =>
                +new Date(a.createdAt) < +new Date(b.createdAt)
                  ? 1
                  : +new Date(a.createdAt) > +new Date(b.createdAt)
                  ? -1
                  : 0
              );

              return {
                user: {
                  ...prev.user,
                  reports: newReports,
                },
              };
            },
          });

          this.props.userData.subscribeToMore({
            document: gql`
              subscription {
                reportUpdated {
                  id
                  createdAt
                  downloaded
                  downloadUrl
                  title
                  collection {
                    id
                    name
                  }
                  producer {
                    id
                    name
                  }
                }
              }
            `,
            updateQuery: (prev, { subscriptionData }) => {
              if (!subscriptionData.data) {
                return prev;
              }

              const newReports = [
                ...prev.user.reports.filter(
                  (report) =>
                    report.id !== subscriptionData.data.reportUpdated.id
                ),
                subscriptionData.data.reportUpdated,
              ].sort((a, b) =>
                a.name.toLowerCase() > b.name.toLowerCase()
                  ? 1
                  : a.name.toLowerCase() < b.name.toLowerCase()
                  ? -1
                  : 0
              );

              return {
                user: {
                  ...prev.user,
                  reports: newReports,
                },
              };
            },
          });

          this.props.userData.subscribeToMore({
            document: gql`
              subscription {
                reportDeleted
              }
            `,
            updateQuery: (prev, { subscriptionData }) => {
              if (!subscriptionData.data) {
                return prev;
              }

              const newReports = prev.user.reports.filter(
                (report) => report.id !== subscriptionData.data.reportDeleted
              );

              return {
                user: {
                  ...prev.user,
                  reports: newReports,
                },
              };
            },
          });
        }

        queryMore = async () => {
          if (
            !this.queryMore.locked &&
            this.props.userData.user.reportCount >
              this.props.userData.user.reports.length
          ) {
            this.queryMore.locked = true;

            try {
              this.setState({ fetchMoreLoading: true });
              await this.props.userData.fetchMore({
                variables: {
                  offset: this.props.userData.user.reports.length,
                  limit:
                    this.props.userData.user.reportCount -
                      this.props.userData.user.reports.length >=
                    20
                      ? 20
                      : this.props.userData.user.reportCount % 20,
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                  if (!fetchMoreResult) {
                    return prev;
                  }

                  const newReports = [
                    ...prev.user.reports,
                    ...fetchMoreResult.user.reports,
                  ];

                  return {
                    user: {
                      ...prev.user,
                      reports: newReports,
                    },
                  };
                },
              });
            } finally {
              this.setState(() => {
                this.queryMore.locked = false;

                return { fetchMoreLoading: false };
              });
            }
          }
        };

        setDownloaded = async (id) => {
          this.setState({ showLoading: true });

          await this.props.mutate({
            variables: {
              id,
            },
            optimisticResponse: {
              __typename: "Mutation",
              updateReport: {
                id,
                downloaded: true,
                __typename: "Report",
              },
            },
          });
        };

        render() {
          const {
            mobile,
            t,
            userData: { error, loading, user, refetch },
          } = this.props;
          const { fetchMoreLoading } = this.state;

          const getReadableDate = (timestamp) =>
            timestamp.isSame(moment(), "day")
              ? t`Today`
              : timestamp.isSameOrAfter(moment().subtract(7, "days"), "day") &&
                timestamp.isBefore(new Date())
              ? t`This week`
              : timestamp.isSameOrAfter(moment().subtract(31, "days"), "day") &&
                timestamp.isBefore(new Date())
              ? t`This month`
              : timestamp.fromNow().charAt(0).toUpperCase() +
                timestamp.fromNow().slice(1);

          return (
            <div style={{ position: "relative", height: "calc(100% - 64px)" }}>
              {user && !user.reports.length ? (
                <Typography
                  variant="h5"
                  className="notSelectable defaultCursor"
                  style={{
                    width: "100%",
                    textAlign: "center",
                    paddingTop: "32px",
                    paddingBottom: "32px",
                    color: textOnMainBackgroundColor,
                  }}
                >
                  {t`No reports`}
                </Typography>
              ) : error ? (
                <ErrorScreen
                  refetch={() =>
                    refetch({
                      limit: 20,
                      offset: 0,
                      filter: {},
                    })
                  }
                  error={error}
                />
              ) : (
                <>
                  <div style={{ height: "100%" }}>
                    <div
                      className="notSelectable"
                      style={{
                        width: "100%",
                        maxWidth: mobile ? "unset" : "768px",
                        height: mobile ? "100%" : "calc(100% - 64px)",
                        margin: "0 auto",
                      }}
                      onScroll={(event) => {
                        if (
                          event.target.scrollTop + event.target.clientHeight >=
                          event.target.scrollHeight - 600
                        ) {
                          this.queryMore();
                        }
                      }}
                    >
                      <Table stickyHeader>
                        <TableHead>
                          <TableRow style={{ height: "64px" }}>
                            <TableCell
                              style={{
                                backgroundColor,
                                width: mobile ? "50%" : "25%",
                              }}
                            >
                              <b>{t`Title`}</b>
                            </TableCell>
                            <TableCell
                              style={{
                                backgroundColor,
                                width: mobile ? "50%" : "25%",
                              }}
                            >
                              <b>{t(collectionName || "Collection")}</b>
                            </TableCell>
                            {!mobile && (
                              <>
                                <TableCell
                                  style={{
                                    backgroundColor,
                                    width: "25%",
                                  }}
                                >
                                  <b>{t`Author`}</b>
                                </TableCell>
                                <TableCell
                                  style={{ backgroundColor, width: "25%" }}
                                >
                                  <b>{t`Created`}</b>
                                </TableCell>
                              </>
                            )}
                            <TableCell style={{ backgroundColor }}>
                              <div style={{ width: "32px" }} />
                              {/* Forces column width to be 64px */}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {(loading
                            ? new Array(20).fill({})
                            : user.reports
                          ).map(
                            ({
                              id,
                              title,
                              producer,
                              collection,
                              createdAt,
                              downloaded,
                              downloadUrl,
                            }) => (
                              <TableRow key={id} style={{ height: "64px" }}>
                                <TableCell
                                  style={{
                                    ...cellStyle,
                                    width: mobile ? "50%" : "25%",
                                  }}
                                >
                                  {loading ? <Skeleton /> : title}
                                </TableCell>
                                <TableCell
                                  style={{
                                    ...cellStyle,
                                    width: mobile ? "50%" : "25%",
                                  }}
                                >
                                  {loading ? <Skeleton /> : collection.name}
                                </TableCell>
                                {!mobile && (
                                  <>
                                    <TableCell
                                      style={{
                                        ...cellStyle,
                                        width: "25%",
                                      }}
                                    >
                                      {loading ? <Skeleton /> : producer.name}
                                    </TableCell>
                                    <TableCell
                                      style={{ ...cellStyle, width: "25%" }}
                                    >
                                      {loading ? (
                                        <Skeleton />
                                      ) : (
                                        getReadableDate(moment(createdAt))
                                      )}
                                    </TableCell>
                                  </>
                                )}
                                <TableCell
                                  style={{
                                    ...cellStyle,
                                    width: "48px",
                                    padding: "0 8px",
                                  }}
                                  align="right"
                                >
                                  {loading ? (
                                    <Skeleton
                                      variant="circular"
                                      style={{
                                        width: "24px",
                                        height: "24px",
                                        margin: "auto",
                                      }}
                                    />
                                  ) : (
                                    <IconButton
                                      component="a"
                                      download
                                      href={
                                        downloadUrl +
                                        "?token=" +
                                        (localStorage.getItem("accountList") &&
                                        localStorage.getItem("userId") &&
                                        JSON.parse(
                                          localStorage.getItem("accountList")
                                        ).filter(
                                          (account) =>
                                            account.id ===
                                            localStorage.getItem("userId")
                                        )[0]
                                          ? JSON.parse(
                                              localStorage.getItem(
                                                "accountList"
                                              )
                                            ).filter(
                                              (account) =>
                                                account.id ===
                                                localStorage.getItem("userId")
                                            )[0].token
                                          : "")
                                      }
                                      onClick={() => this.setDownloaded(id)}
                                      size="large"
                                    >
                                      <StyledBadge
                                        color="secondary"
                                        variant="dot"
                                        invisible={downloaded}
                                        style={{ zIndex: 0 }}
                                      >
                                        <SvgIcon>
                                          <svg
                                            style={{
                                              width: "24px",
                                              height: "24px",
                                            }}
                                            viewBox="0 0 24 24"
                                          >
                                            <g>
                                              <rect
                                                fill="none"
                                                height="24"
                                                width="24"
                                              />
                                            </g>
                                            <g>
                                              <path d="M18,15v3H6v-3H4v3c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2v-3H18z M17,11l-1.41-1.41L13,12.17V4h-2v8.17L8.41,9.59L7,11l5,5 L17,11z" />
                                            </g>
                                          </svg>
                                        </SvgIcon>
                                      </StyledBadge>
                                    </IconButton>
                                  )}
                                </TableCell>
                              </TableRow>
                            )
                          )}
                        </TableBody>
                      </Table>
                    </div>
                  </div>
                  {fetchMoreLoading && (
                    <LinearProgress
                      style={
                        this.props.mobile
                          ? {
                              position: "absolute",
                              top: 0,
                              width: "100%",
                              maxWidth: "unset",
                              margin: "0 auto",
                              zIndex: 100,
                            }
                          : {
                              margin: "-4px auto 0 auto",
                              width: "100%",
                              maxWidth: "768px",
                            }
                      }
                    />
                  )}
                </>
              )}
              {!!process.env.REACT_APP_SHOW_REPORT_GENERATOR && (
                <Fab
                  variant="extended"
                  color="secondary"
                  style={{ position: "absolute", right: 24, bottom: 24 }}
                  component="a"
                  href="https://agrentareportgenerator.z22.web.core.windows.net/"
                  target="_blank"
                >
                  <Add
                    style={{
                      marginRight: "8px",
                    }}
                  />
                  {t`Report`}
                </Fab>
              )}
            </div>
          );
        }
      }
    )
  )
);
