/* globals Connection */
import React, { Component } from "react";
import AuthenticatedApp from "./AuthenticatedApp";
import { Route, Switch, Redirect } from "react-router-dom";
import {
  theme,
  UnauthenticatedApp,
  Error404,
  OfflineError,
  SharedUIProvider,
  debounce,
  importAll,
} from "@igloocloud/igloosharedui";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import { useTheme, useMediaQuery } from "@mui/material";
import querystringify from "querystringify";
import logo from "./styles/assets/logo.svg";
import backgroundLogo from "./styles/assets/background-logo.svg";
import unauthenticatedLogo from "./styles/assets/unauthenticated-logo.svg";
import mobileUnauthenticatedLogo from "./styles/assets/mobile-unauthenticated-logo.svg";
import { withTranslation } from "react-i18next";
import moment from "moment";
import { version } from "../package.json";

const appName = process.env.REACT_APP_NAME;

function setupWebPush(token, id) {
  const applicationServerPublicKey =
    "BOZG_RBpt8yVp6J1JN08zCEPSFbYC_aHQQKNY0isQDnozk9GXZAiSHMnnXowvfacQeh38j2TQAyp9yT0qpUXS6Y";

  function urlB64ToUint8Array(base64String) {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
      .replace(/-/g, "+")
      .replace(/_/g, "/");

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }

  // checks whether the browser supports push service workers and push notifications
  if ("serviceWorker" in navigator && "PushManager" in window) {
    navigator.serviceWorker.register("webPushSw.js").then(function (swReg) {
      // checks whether user is already subscribed to push notifications
      swReg.pushManager.getSubscription().then(function (subscription) {
        const isSubscribed = subscription !== null;

        if (isSubscribed) {
          sendSubscriptionToServer(subscription, id);
        } else {
          // subscribes user
          const applicationServerKey = urlB64ToUint8Array(
            applicationServerPublicKey
          );

          swReg.pushManager
            .subscribe({
              userVisibleOnly: true,
              applicationServerKey: applicationServerKey,
            })
            .then(function (subscription) {
              sendSubscriptionToServer(subscription, id);
            });
        }
      });
    });
  }

  function sendSubscriptionToServer(subscription, id) {
    if (
      JSON.parse(localStorage.getItem("endpointList")).find(
        (endpoint) => endpoint.id === id
      )
    ) {
      localStorage.setItem(
        "endpointList",
        JSON.stringify(
          JSON.parse(localStorage.getItem("endpointList")).filter(
            (endpoint) => endpoint.id !== id
          )
        )
      );
    }

    let newEndpointList = JSON.parse(localStorage.getItem("endpointList"));

    newEndpointList.push({
      id,
      endpoint: subscription.endpoint,
    });

    localStorage.setItem("endpointList", JSON.stringify(newEndpointList));

    // the server URL changes based on whether the server setting is set to auto or manual
    const serverUrl = localStorage.getItem("server")
      ? (localStorage.getItem("serverUnsecure") === "true"
          ? "http://"
          : "https://") +
        localStorage.getItem("server") +
        "/web-push-subscribe"
      : `https://v1.igloo.ooo/web-push-subscribe`;

    fetch(serverUrl, {
      body: JSON.stringify(subscription),
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "user-agent": "Mozilla/4.0 MDN Example",
        "content-type": "application/json",
        authorization: "Bearer " + token,
      },
      method: "POST",
      mode: "cors",
      redirect: "follow",
      referrer: "no-referrer",
    });
  }
}

function webPushUnsubscribe(token, id, endpoint) {
  // the server URL changes based on whether the server setting is set to auto or manual
  const serverUrl = localStorage.getItem("server")
    ? (localStorage.getItem("serverUnsecure") === "true"
        ? "http://"
        : "https://") +
      localStorage.getItem("server") +
      "/web-push-unsubscribe"
    : `https://v1.igloo.ooo/web-push-unsubscribe`;

  fetch(serverUrl, {
    body: JSON.stringify({
      endpoint,
    }),
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "user-agent": "Mozilla/4.0 MDN Example",
      "content-type": "application/json",
      authorization: "Bearer " + token,
    },
    method: "POST",
    mode: "cors",
    redirect: "follow",
    referrer: "no-referrer",
  });

  localStorage.setItem(
    "endpointList",
    JSON.stringify(
      JSON.parse(localStorage.getItem("endpointList")).filter(
        (endpoint) => endpoint.id !== id
      )
    )
  );
}

export default (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return <App {...props} fullScreen={fullScreen} />;
};

const App = withTranslation()(
  class App extends Component {
    constructor() {
      super();

      window.backStack = 0;

      let bearer = "";
      // reuses the previous session bearer if present

      if (!localStorage.getItem("accountList")) {
        localStorage.setItem("accountList", "[]");
      }
      if (!localStorage.getItem("endpointList")) {
        localStorage.setItem("endpointList", "[]");
      }
      if (!localStorage.getItem("language")) {
        localStorage.setItem(
          "language",
          (navigator.language || navigator.userLanguage).slice(0, 2)
        );
      }

      bearer =
        localStorage.getItem("accountList") &&
        localStorage.getItem("userId") &&
        JSON.parse(localStorage.getItem("accountList")).filter(
          (account) => account.id === localStorage.getItem("userId")
        )[0]
          ? JSON.parse(localStorage.getItem("accountList")).filter(
              (account) => account.id === localStorage.getItem("userId")
            )[0].token
          : "";

      JSON.parse(localStorage.getItem("accountList")).forEach(
        (account) => account.token && setupWebPush(account.token, account.id)
      );

      if (!sessionStorage.getItem("loginBackground")) {
        const images = importAll(
          require.context(
            "./styles/assets/loginBackgrounds",
            false,
            /\.(png|jpe?g|svg)$/
          )
        );

        sessionStorage.setItem(
          "loginBackground",
          Math.floor(Math.random() * Object.keys(images).length)
        );
      }

      this.state = {
        bearer,
        isMobile: window.innerWidth < 600,
        from: "",
        loggedOut: false,
        loginEmail: "",
        loginEmailError: "",
        loginPassword: "",
        signupEmail: "",
        signupEmailError: "",
        signupPassword: "",
        name: "",
        changeEmailBearer: "",
        changeAuthenticationBearer: "",
        deleteUserBearer: "",
        manageAccessTokensBearer: "",
        online: true,
      };
    }

    updateDimensions = () => {
      if (window.innerWidth < 600) {
        !this.state.isMobile && this.setState({ isMobile: true });
      } else {
        this.state.isMobile && this.setState({ isMobile: false });
      }
    };

    setOnline = (online) => this.setState({ online });

    handleBackButton = (e) => {
      e.preventDefault();

      if (
        (window.location.hash === "#/" ||
          window.location.hash ===
            (JSON.parse(localStorage.getItem("accountList"))[0]
              ? "#/accounts"
              : "#/signup")) &&
        !window.backStack
      ) {
        navigator.Backbutton.goBack();
      }

      if (!window.backStack) {
        if (
          window.location.hash.split("?").length === 2 &&
          querystringify.parse("?" + window.location.hash.split("?")[1])
            .collection
        ) {
          if (
            querystringify.parse("?" + window.location.hash.split("?")[1])
              .thing &&
            this.state.isMobile
          ) {
            //main body to sidebar (mobile mode online)
            this.setState({
              redirectTo:
                "/?collection=" +
                querystringify.parse("?" + window.location.hash.split("?")[1])
                  .collection,
            });
          } else {
            //sidebar (or desktop main body) to collections
            this.setState({
              redirectTo: "/",
            });
          }
        }

        if (window.location.hash === "#/signup") {
          this.setState({
            redirectTo: "/accounts",
          });
        }

        if (window.location.hash.startsWith("#/login")) {
          if (
            querystringify.parse("?" + window.location.hash.split("?")[1]).user
          ) {
            this.setState({
              redirectTo: "/accounts",
            });
          } else {
            this.setState({
              redirectTo: JSON.parse(localStorage.getItem("accountList"))[0]
                ? "/accounts"
                : "/signup",
            });
          }
        }

        if (window.location.hash === "#/recovery") {
          this.setState({
            redirectTo: "/login",
          });
        }
      }
    };

    componentDidMount() {
      this.updateDimensions();
      window.addEventListener("resize", debounce(this.updateDimensions));

      if (window.cordova) {
        this.setState({
          online: navigator.connection.type !== Connection.NONE,
        });

        document.addEventListener("offline", this.setOnline, false);
        document.addEventListener("online", this.setOnline, false);
        document.addEventListener("backbutton", this.handleBackButton);

        window.StatusBar.show();
        window.StatusBar.overlaysWebView(true);
      }
    }

    componentWillUnmount() {
      window.removeEventListener("resize", debounce(this.updateDimensions));

      if (window.cordova) {
        document.removeEventListener("offline", this.setOnline);
        document.removeEventListener("online", this.setOnline);
        document.removeEventListener("backbutton", this.handleBackButton);
      }
    }

    render() {
      const { i18n } = this.props;
      const { online } = this.state;
      const {
        REACT_APP_ENABLE_MAPS: enableMaps,
        REACT_APP_ENABLE_DATA_LAB: enableDataLab,
        REACT_APP_ENABLE_REPORTS: enableReports,
        REACT_APP_ENABLE_ACTIVITIES: enableActivities,
      } = process.env;

      moment.updateLocale(
        i18n.language,
        i18n.language === "en"
          ? require("moment/locale/en-gb")
          : require("moment/locale/" + i18n.language)
      );

      if (window.cordova) {
        window.StatusBar.overlaysWebView(true);
      }

      const logOut = (deleted) => {
        let currentAccountList = JSON.parse(
          localStorage.getItem("accountList")
        );
        const currentUser = currentAccountList.find(
          (account) => account.id === localStorage.getItem("userId")
        );

        currentUser &&
          JSON.parse(localStorage.getItem("endpointList")).find(
            (endpoint) => endpoint.id === currentUser.id
          ) &&
          webPushUnsubscribe(
            currentUser.token,
            currentUser.id,
            JSON.parse(localStorage.getItem("endpointList")).find(
              (endpoint) => endpoint.id === currentUser.id
            ).endpoint
          );

        deleted
          ? (currentAccountList = currentAccountList.filter(
              (account) => account.id !== localStorage.getItem("userId")
            ))
          : currentAccountList.filter(
              (account) => account.id === localStorage.getItem("userId")
            )[0] &&
            (currentAccountList.filter(
              (account) => account.id === localStorage.getItem("userId")
            )[0].token = "");

        localStorage.setItem("accountList", JSON.stringify(currentAccountList));

        localStorage.setItem("userId", "");

        this.setState({
          bearer: "",
          loggedOut: true,
          loginEmail: "",
          signupEmail: "",
        });
      };

      const changeAccount = (userId, redirect) => {
        this.setState({
          bearer: "",
          loggedOut: true,
          userId,
          redirect,
          signupEmail: "",
        });
        localStorage.setItem("userId", "");
      };

      if (
        querystringify.parse(
          window.cordova && window.location.hash.split("?").length === 2
            ? "?" + window.location.hash.split("?")[1]
            : window.location.search
        ).user &&
        localStorage.getItem("accountList") &&
        JSON.parse(localStorage.getItem("accountList")).find(
          (account) =>
            account.id ===
            querystringify.parse(
              window.cordova && window.location.hash.split("?").length === 2
                ? "?" + window.location.hash.split("?")[1]
                : window.location.search
            ).user
        ) &&
        (window.cordova
          ? window.location.hash.split("?")[0] === "#/" ||
            window.location.hash.split("?")[0] === "#/data" ||
            window.location.hash.split("?")[0] === "#/reports"
          : window.location.pathname === "/" ||
            window.location.pathname === "/data" ||
            window.location.pathname === "/reports")
      ) {
        localStorage.setItem(
          "userId",
          querystringify.parse(
            window.cordova && window.location.hash.split("?").length === 2
              ? "?" + window.location.hash.split("?")[1]
              : window.location.search
          ).user
        );

        this.setState({
          bearer: JSON.parse(localStorage.getItem("accountList")).find(
            (account) =>
              account.id ===
              querystringify.parse(
                window.cordova && window.location.hash.split("?").length === 2
                  ? "?" + window.location.hash.split("?")[1]
                  : window.location.search
              ).user
          ).token,
        });

        return (
          <Redirect
            to={
              window.location.pathname +
              (querystringify.parse(
                window.cordova && window.location.hash.split("?").length === 2
                  ? "?" + window.location.hash.split("?")[1]
                  : window.location.search
              ).thing
                ? "?thing=" +
                  querystringify.parse(
                    window.cordova &&
                      window.location.hash.split("?").length === 2
                      ? "?" + window.location.hash.split("?")[1]
                      : window.location.search
                  ).thing
                : "")
            }
          />
        );
      }

      if (this.state.redirectTo) {
        let address = this.state.redirectTo;
        this.setState({ redirectTo: "" });
        return <Redirect push to={address} />;
      }

      if (this.state.redirectToHidden) {
        let address = this.state.redirectToHidden;
        this.setState({ redirectToHidden: "" });
        return <Redirect to={address} />;
      }

      return (
        <SharedUIProvider
          value={{
            ...process.env,
            i18n,
            moment,
            logo,
            backgroundLogo,
            unauthenticatedLogo,
            mobileUnauthenticatedLogo,
            loginBackgrounds: Object.values(
              importAll(
                require.context(
                  "./styles/assets/loginBackgrounds",
                  false,
                  /\.(png|jpe?g|svg)$/
                )
              )
            ).map((module) => module["default"]),
            version,
          }}
        >
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme(process.env)}>
              {online ? (
                <>
                  <Switch>
                    <Route
                      exact
                      path={[
                        ...(enableMaps ? ["/maps"] : []),
                        "/",
                        ...(enableReports ? ["/reports"] : []),
                        ...(enableDataLab ? ["/data"] : []),
                        ...(enableActivities ? ["/activities"] : []),
                      ]}
                    >
                      {(() => {
                        if (this.state.bearer) {
                          return (
                            <AuthenticatedApp
                              bearer={this.state.bearer}
                              logOut={logOut}
                              changeBearer={(bearer) =>
                                this.setState({ bearer })
                              }
                              changeAccount={changeAccount}
                              isMobile={this.state.isMobile}
                              forceUpdate={() => this.forceUpdate()}
                              changeEmail={(loginEmail) =>
                                this.setState({ loginEmail })
                              }
                              changeEmailBearer={this.state.changeEmailBearer}
                              changeAuthenticationBearer={
                                this.state.changeAuthenticationBearer
                              }
                              deleteUserBearer={this.state.deleteUserBearer}
                              manageAccessTokensBearer={
                                this.state.manageAccessTokensBearer
                              }
                            />
                          );
                        } else {
                          // if the bearer is not present because the user chose to log out in this session, their referrer is not saved
                          // the referrer is the address of the page on which the user was before being asked to log in
                          if (!this.state.loggedOut) {
                            // this check avoids redirecting the user to remote.igloo.ooo/?collectionundefined in case there is nothing after ?collection=
                            window.location.href.split("?collection=")[1] &&
                              this.setState({
                                from:
                                  "/?collection=" +
                                  window.location.href.split("?collection=")[1],
                              });
                          }
                          // the user is redirected to the log in screen if someone already logged in on their machine
                          return JSON.parse(
                            localStorage.getItem("accountList")
                          )[0] ? (
                            <Redirect to="/accounts" />
                          ) : (
                            <Redirect to="/signup" />
                          );
                        }
                      })()}
                    </Route>
                    <Route path="/accounts">
                      <UnauthenticatedApp
                        isAccountSwitcher
                        forceUpdate={() => this.forceUpdate()}
                        changeBearer={(bearer) => this.setState({ bearer })}
                        setupWebPush={setupWebPush}
                        setRedirect={(redirectTo) =>
                          this.setState({ redirectTo })
                        }
                        appName={appName}
                      />
                    </Route>
                    <Route path="/login">
                      <UnauthenticatedApp
                        isLogin
                        forceUpdate={this.forceUpdate}
                        changeBearer={(bearer) => this.setState({ bearer })}
                        setupWebPush={setupWebPush}
                        setRedirect={(redirectTo) =>
                          this.setState({ redirectTo })
                        }
                        setHiddenRedirect={(redirectToHidden) =>
                          this.setState({ redirectToHidden })
                        }
                        appName={appName}
                      />
                    </Route>
                    <Route path="/signup">
                      <UnauthenticatedApp
                        isSignup
                        forceUpdate={() => this.forceUpdate()}
                        changeBearer={(bearer) => this.setState({ bearer })}
                        setupWebPush={setupWebPush}
                        setRedirect={(redirectTo) =>
                          this.setState({ redirectTo })
                        }
                        appName={appName}
                      />
                    </Route>
                    <Route path="/recovery">
                      <UnauthenticatedApp
                        isRecovery
                        setRedirect={(redirectTo) =>
                          this.setState({ redirectTo })
                        }
                        changeBearer={(bearer) => this.setState({ bearer })}
                        appName={appName}
                      />
                    </Route>
                    <Route>
                      <Error404
                        isMobile={this.state.isMobile}
                        setRedirect={(redirectTo) =>
                          this.setState({ redirectTo })
                        }
                        appName={appName}
                      />
                    </Route>
                  </Switch>
                  {this.state.redirect && (
                    <Redirect push to={"/login?user=" + this.state.userId} />
                  )}
                </>
              ) : (
                <OfflineError appName={appName} />
              )}
            </ThemeProvider>
          </StyledEngineProvider>
        </SharedUIProvider>
      );
    }
  }
);
