import { ApolloQueryResult, gql, useQuery } from "@apollo/client";
import { isNoneNullish, useUserLocation } from "@igloocloud/igloosharedui";
import L from "leaflet";
import { ReactElement, useEffect, useRef } from "react";
import { QueryData } from "./Maps.types";
import { withRouter } from "react-router-dom";

const mapId = "map";

const userMarker =
  '<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"> <g filter="url(#filter0_d_101_2)">  <circle cx="16" cy="16" r="12" fill="#0057CB"/>  </g>  <path d="M16 15.9792C15.0833 15.9792 14.3333 15.6875 13.75 15.1042C13.1667 14.5208 12.875 13.7708 12.875 12.8542C12.875 11.9375 13.1667 11.1875 13.75 10.6042C14.3333 10.0208 15.0833 9.72917 16 9.72917C16.9167 9.72917 17.6667 10.0208 18.25 10.6042C18.8333 11.1875 19.125 11.9375 19.125 12.8542C19.125 13.7708 18.8333 14.5208 18.25 15.1042C17.6667 15.6875 16.9167 15.9792 16 15.9792ZM9.33333 22.6667V20.7083C9.33333 20.1806 9.46528 19.7292 9.72917 19.3542C9.99305 18.9792 10.3333 18.6944 10.75 18.5C11.6806 18.0833 12.5729 17.7708 13.4271 17.5625C14.2812 17.3542 15.1389 17.25 16 17.25C16.8611 17.25 17.7153 17.3576 18.5625 17.5729C19.4097 17.7882 20.2981 18.0986 21.2275 18.504C21.6623 18.7002 22.0108 18.9847 22.2731 19.3575C22.5355 19.7303 22.6667 20.1806 22.6667 20.7083V22.6667H9.33333Z" fill="white"/>  <defs>  <filter id="filter0_d_101_2" x="0" y="0" width="32" height="32" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">  <feFlood flood-opacity="0" result="BackgroundImageFix"/>  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>  <feOffset/>  <feGaussianBlur stdDeviation="2"/>  <feComposite in2="hardAlpha" operator="out"/>  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_101_2"/>  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_101_2" result="shape"/>  </filter>  </defs>  </svg>';
export const userMarkerUrl = "data:image/svg+xml;base64," + btoa(userMarker);

const thingMarker =
  '<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">  <g filter="url(#filter0_d_301_2)">      <circle cx="16" cy="16" r="12" fill="#0057CB" />  </g>  <path      d="M24 13.5V12H22.5V10.5C22.5 10.1022 22.342 9.72064 22.0607 9.43934C21.7794 9.15804 21.3978 9 21 9H10.5C10.1022 9 9.72064 9.15804 9.43934 9.43934C9.15804 9.72064 9 10.1022 9 10.5V21C9 21.3978 9.15804 21.7794 9.43934 22.0607C9.72064 22.342 10.1022 22.5 10.5 22.5H21C21.3978 22.5 21.7794 22.342 22.0607 22.0607C22.342 21.7794 22.5 21.3978 22.5 21V19.5H24V18H22.5V16.5H24V15H22.5V13.5H24ZM21 21H10.5V10.5H21V21ZM12 16.5H15.75V19.5H12V16.5ZM16.5 12H19.5V14.25H16.5V12ZM12 12H15.75V15.75H12V12ZM16.5 15H19.5V19.5H16.5V15Z"      fill="white" />  <defs>      <filter id="filter0_d_301_2" x="0" y="0" width="32" height="32" filterUnits="userSpaceOnUse"          color-interpolation-filters="sRGB">          <feFlood flood-opacity="0" result="BackgroundImageFix" />          <feColorMatrix in="SourceAlpha" type="matrix"              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />          <feOffset />          <feGaussianBlur stdDeviation="2" />          <feComposite in2="hardAlpha" operator="out" />          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_301_2" />          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_301_2" result="shape" />      </filter>  </defs></svg>';
export const thingMarkerUrl = "data:image/svg+xml;base64," + btoa(thingMarker);

const setUpMap = (
  map: L.map,
  userLatitude: number,
  userLongitude: number,
  data: QueryData,
  push: (route: string) => void,
  mainColor: string,
  refetch: (
    variables?: Partial<{
      minLat: any;
      maxLat: any;
      minLon: any;
      maxLon: any;
    }>
  ) => Promise<ApolloQueryResult<any>>
) => {
  let tempMap = map.current;

  if (
    !map.current &&
    document.getElementById(mapId) &&
    !document.getElementById(mapId).childNodes.length
  ) {
    tempMap = L.map(mapId, {
      attributionControl: false,
      zoomControl: false,
    }).setView(
      isNoneNullish(userLatitude, userLongitude)
        ? [userLatitude, userLongitude]
        : [42.4, 12.9],
      6
    );

    L.tileLayer(
      "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
      {
        maxZoom: 19,
      }
    ).addTo(tempMap);
  }

  if (data) {
    L.geoJSON(
      [
        {
          type: "FeatureCollection",
          features: data.user.geometries.map(
            ({ geoJSON: { coordinates, type } }) => ({
              type: "Feature",
              properties: {},
              geometry: {
                coordinates,
                type,
              },
            })
          ),
        },
      ],
      {
        style: {
          color: mainColor,
          fillOpacity: 0.08,
          fillColor: mainColor,
        },
      }
    ).addTo(tempMap);
  }

  if (userLatitude && userLongitude) {
    L.marker([userLatitude, userLongitude], {
      icon: L.icon({ iconUrl: userMarkerUrl, iconAnchor: [12, 12] }),
    }).addTo(tempMap);
  }

  if (data?.user.things) {
    data.user.things.forEach((thing) => {
      if (isNoneNullish(thing.lat, thing.lon)) {
        L.marker([thing.lat, thing.lon], {
          icon: L.icon({ iconUrl: thingMarkerUrl, iconAnchor: [12, 12] }),
        })
          .on("click", () =>
            push(`/?collection=${thing.collection.id}&thing=${thing.id}`)
          )
          .addTo(tempMap);
      }
    });

    tempMap.on("moveend", () =>
      refetch({
        minLat: tempMap?.getBounds()._southWest.lat,
        maxLat: tempMap?.getBounds()._northEast.lat,
        minLon: tempMap?.getBounds()._southWest.lng,
        maxLon: tempMap?.getBounds()._northEast.lng,
      })
    );
  }

  map.current = tempMap;
};

const Maps = withRouter(({ history }): ReactElement => {
  const map = useRef<L.Map>(null);
  const { REACT_APP_MAIN_COLOR: mainColor } = process.env;

  const [userLatitude, userLongitude] = useUserLocation();
  const { data, refetch } = useQuery(
    gql`
      query (
        $minLat: Float!
        $maxLat: Float!
        $minLon: Float!
        $maxLon: Float!
      ) {
        user {
          id
          geometryCount(
            minLat: $minLat
            maxLat: $maxLat
            minLon: $minLon
            maxLon: $maxLon
          )
          geometries(
            limit: 20
            minLat: $minLat
            maxLat: $maxLat
            minLon: $minLon
            maxLon: $maxLon
          ) {
            id
            geoJSON
          }
          thingCount(
            minLat: $minLat
            maxLat: $maxLat
            minLon: $minLon
            maxLon: $maxLon
          )
          things(
            limit: 20
            minLat: $minLat
            maxLat: $maxLat
            minLon: $minLon
            maxLon: $maxLon
          ) {
            id
            lat
            lon
            collection {
              id
            }
          }
        }
      }
    `,
    {
      skip: !map.current,
      variables: {
        minLat: map.current?.getBounds()._southWest.lat,
        maxLat: map.current?.getBounds()._northEast.lat,
        minLon: map.current?.getBounds()._southWest.lng,
        maxLon: map.current?.getBounds()._northEast.lng,
      },
    }
  );

  setTimeout(
    () =>
      setUpMap(
        map,
        userLatitude,
        userLongitude,
        data,
        open,
        mainColor,
        refetch
      ),
    0
  );

  useEffect(
    () =>
      setUpMap(
        map,
        userLatitude,
        userLongitude,
        data,
        history.push,
        mainColor,
        refetch
      ),
    [map, userLatitude, userLongitude, !!map.current]
  );

  return <div id="map" style={{ width: "100%", height: "100%" }} />;
});

export default Maps;
