import React, { Component } from "react";
import Sidebar from "./components/Sidebar";
import SidebarHeader from "./components/SidebarHeader";
import "./styles/App.css";
import "./styles/MobileApp.css";
import "./styles/Cards.css";
import backgroundLogo from "./styles/assets/background-logo.svg";
import { Redirect } from "react-router-dom";
import querystringify from "querystringify";
import { graphql } from "@apollo/react-hoc";
import { gql } from "@apollo/client";
import Helmet from "react-helmet";
import IsOnline from "is-online-component";
import AppBar from "@mui/material/AppBar";
import ThingFetcher from "./ThingFetcher";
import { getNotchHeight } from "@igloocloud/igloosharedui";
import tinyColor from "tinycolor2";

const {
  REACT_APP_MAIN_BACKGROUND_COLOR: backgroundColor,
  REACT_APP_LIGHT_COLOR: lightColor,
  REACT_APP_NAME: appName,
  REACT_APP_SIDEBAR_FOLDERS_ENABLED: foldersEnabled,
} = process.env;

export default graphql(
  foldersEnabled
    ? gql`
        query (
          $id: ID!
          $offset: NaturalNumber
          $filter: ThingFilter
          $limit: NaturalNumber!
        ) {
          collection(id: $id) {
            id
            name
            myRole
            thingCount
            uniqueFirmwares
            things(
              filter: $filter
              sortBy: name
              sortDirection: ASCENDING
              limit: $limit
              offset: $offset
            ) {
              id
              index
              name
              online
              type
              firmware
              createdAt
              updatedAt
              notificationCount(filter: { read: false })
              lastReadNotification: lastNotification(filter: { read: true }) {
                id
              }
              lastUnreadNotification: lastNotification(
                filter: { read: false }
              ) {
                id
                content
                read
              }
            }
          }
        }
      `
    : gql`
        query (
          $id: ID!
          $offset: NaturalNumber
          $filter: [ThingFilter!]
          $limit: NaturalNumber!
        ) {
          collection(id: $id) {
            id
            name
            myRole
            thingCount
            uniqueFirmwares
            starredThings: things(
              filter: { starred: true }
              sortBy: name
              sortDirection: ASCENDING
              limit: 5
            ) {
              id
              index
              name
              online
              type
              firmware
              createdAt
              updatedAt
              starred
              notificationCount(filter: { read: false })
              lastReadNotification: lastNotification(filter: { read: true }) {
                id
              }
              lastUnreadNotification: lastNotification(
                filter: { read: false }
              ) {
                id
                content
                read
              }
            }
            things(
              filter: { starred: false, AND: $filter }
              sortBy: name
              sortDirection: ASCENDING
              limit: $limit
              offset: $offset
            ) {
              id
              index
              name
              online
              type
              firmware
              createdAt
              updatedAt
              starred
              notificationCount(filter: { read: false })
              lastReadNotification: lastNotification(filter: { read: true }) {
                id
              }
              lastUnreadNotification: lastNotification(
                filter: { read: false }
              ) {
                id
                content
                read
              }
            }
          }
        }
      `,
  {
    name: "collectionData",
    options: ({ collectionId }) => ({
      variables: {
        id: collectionId,
        offset: 0,
        limit: 20,
        filter: {},
      },
    }),
  }
)(
  class Main extends Component {
    state = {
      drawer: false,
      showMainHidden: false,
      hiddenNotifications: false,
      slideIndex: 0,
      areSettingsOpen: false,
      fabWidth: 0,
    };

    changeDrawerState = () => {
      if (!this.state.areSettingsOpen)
        this.setState((oldState) => ({
          drawer: !oldState.drawer,
        }));
    };

    constructor() {
      super();

      this.state = {
        showHidden: false,
        isCardFullScreen: false,
        drawer: false,
        copyMessageOpen: false,
        deselectThing: false,
        slideIndex: 0,
      };
    }

    changeShowHiddenState = () =>
      this.setState((oldState) => ({
        showMainHidden: !oldState.showMainHidden,
      }));

    showHiddenNotifications = () =>
      this.setState((oldState) => ({
        hiddenNotifications: !oldState.hiddenNotifications,
      }));

    componentDidMount() {
      this.props.collectionData.refetch();

      this.props.collectionData.subscribeToMore({
        document: gql`
          subscription ($collectionId: ID) {
            thingUpdated(collectionId: $collectionId) {
              id
              lat
              lon
              angle
              index
              name
              online
              type
              firmware
              createdAt
              updatedAt
              starred
              collection {
                id
              }
              notificationCount(filter: { read: false })
              lastReadNotification: lastNotification(filter: { read: true }) {
                id
              }
              lastUnreadNotification: lastNotification(
                filter: { read: false }
              ) {
                id
                content
                read
              }
            }
          }
        `,
        variables: { collectionId: this.props.collectionId },
        updateQuery: (prev, { subscriptionData }) => {
          if (
            !subscriptionData.data ||
            subscriptionData.data.thingUpdated.collection.id !==
              prev.collection.id
          ) {
            return prev;
          }

          if (subscriptionData.data.thingUpdated.starred && !foldersEnabled) {
            const starredThings = [
              ...prev.collection.starredThings.filter(
                (thing) => thing.id !== subscriptionData.data.thingUpdated.id
              ),
              subscriptionData.data.thingUpdated,
            ].sort((a, b) =>
              a.name.toLowerCase() > b.name.toLowerCase()
                ? 1
                : a.name.toLowerCase() < b.name.toLowerCase()
                ? -1
                : 0
            );

            const things = [
              ...prev.collection.things.filter(
                (thing) => thing.id !== subscriptionData.data.thingUpdated.id
              ),
            ];

            return {
              collection: {
                ...prev.collection,
                starredThings,
                things,
              },
            };
          } else {
            const things = [
              ...prev.collection.things.filter(
                (thing) => thing.id !== subscriptionData.data.thingUpdated.id
              ),
              subscriptionData.data.thingUpdated,
            ].sort((a, b) =>
              a.name.toLowerCase() > b.name.toLowerCase()
                ? 1
                : a.name.toLowerCase() < b.name.toLowerCase()
                ? -1
                : 0
            );

            return foldersEnabled
              ? {
                  collection: {
                    ...prev.collection,
                    things,
                  },
                }
              : {
                  collection: {
                    ...prev.collection,
                    starredThings: prev.collection.starredThings.filter(
                      (thing) =>
                        thing.id !== subscriptionData.data.thingUpdated.id
                    ),
                    things,
                  },
                };
          }
        },
      });

      this.props.collectionData.subscribeToMore({
        document: gql`
          subscription ($collectionId: ID) {
            thingPaired(collectionId: $collectionId) {
              id
              collection {
                id
              }
              index
              name
              online
              type
              firmware
              createdAt
              updatedAt
              starred
              notificationCount(filter: { read: false })
              lastReadNotification: lastNotification(filter: { read: true }) {
                id
              }
              lastUnreadNotification: lastNotification(
                filter: { read: false }
              ) {
                id
                content
                read
              }
            }
          }
        `,
        variables: { collectionId: this.props.collectionId },
        updateQuery: (prev, { subscriptionData }) => {
          if (
            !subscriptionData.data ||
            subscriptionData.data.thingPaired.collection.id !==
              prev.collection.id ||
            prev.collection.starredThings?.some(
              (thing) => thing.id === subscriptionData.data.thingPaired.id
            ) ||
            prev.collection.things.some(
              (thing) => thing.id === subscriptionData.data.thingPaired.id
            )
          ) {
            return prev;
          }

          const newThings =
            subscriptionData.data.thingPaired.starred && !foldersEnabled
              ? [
                  ...prev.collection.starredThings,
                  subscriptionData.data.thingPaired,
                ]
              : [...prev.collection.things, subscriptionData.data.thingPaired];

          newThings.sort(function (a, b) {
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            }
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }
            return 0;
          });

          return subscriptionData.data.thingPaired.starred && !foldersEnabled
            ? {
                collection: {
                  ...prev.collection,
                  starredThings: newThings,
                },
              }
            : {
                collection: {
                  ...prev.collection,
                  things: newThings,
                },
              };
        },
      });

      this.props.collectionData.subscribeToMore({
        document: gql`
          subscription ($collectionId: ID) {
            thingDeleted(collectionId: $collectionId)
          }
        `,
        variables: { collectionId: this.props.collectionId },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) {
            return prev;
          }

          const newThings =
            !foldersEnabled &&
            prev.collection.starredThings.some(
              (starredThing) =>
                starredThing.id === subscriptionData.data.thingDeleted
            )
              ? prev.collection.starredThings.filter(
                  (starredThing) =>
                    starredThing.id !== subscriptionData.data.thingDeleted
                )
              : prev.collection.things.filter(
                  (thing) => thing.id !== subscriptionData.data.thingDeleted
                );

          return !foldersEnabled &&
            prev.collection.starredThings.some(
              (starredThing) =>
                starredThing.id === subscriptionData.data.thingDeleted
            )
            ? {
                collection: {
                  ...prev.collection,
                  starredThing: newThings,
                },
              }
            : {
                collection: {
                  ...prev.collection,
                  things: newThings,
                },
              };
        },
      });

      this.props.collectionData.subscribeToMore({
        document: gql`
          subscription ($collectionId: ID) {
            thingUnpaired(collectionId: $collectionId)
          }
        `,
        variables: { collectionId: this.props.collectionId },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) {
            return prev;
          }

          const newThings =
            !foldersEnabled &&
            prev.collection.starredThings.some(
              (starredThing) =>
                starredThing.id === subscriptionData.data.thingUnpaired
            )
              ? prev.collection.starredThings.filter(
                  (starredThing) =>
                    starredThing.id !== subscriptionData.data.thingUnpaired
                )
              : prev.collection.things.filter(
                  (thing) => thing.id !== subscriptionData.data.thingUnpaired
                );

          return !foldersEnabled &&
            prev.collection.starredThings.some(
              (starredThing) =>
                starredThing.id === subscriptionData.data.thingUnpaired
            )
            ? {
                collection: {
                  ...prev.collection,
                  starredThings: newThings,
                },
              }
            : {
                collection: {
                  ...prev.collection,
                  things: newThings,
                },
              };
        },
      });

      this.props.collectionData.subscribeToMore({
        document: gql`
          subscription ($collectionId: ID) {
            thingMoved(collectionId: $collectionId) {
              id
              index
              name
              online
              type
              createdAt
              updatedAt
              notificationCount(filter: { read: false })
              collection {
                id
              }
              lastReadNotification: lastNotification(filter: { read: true }) {
                id
              }
              lastUnreadNotification: lastNotification(
                filter: { read: false }
              ) {
                id
                content
                read
              }
            }
          }
        `,
        variables: { collectionId: this.props.collectionId },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) {
            return prev;
          }

          if (
            this.props.collectionId ===
            subscriptionData.data.thingMoved.collection.id
          ) {
            const newThings = [
              ...prev.collection.things,
              subscriptionData.data.thingMoved,
            ].filter(
              (thing, index, self) =>
                index === self.findIndex((self) => self.id === thing.id)
            );

            newThings.sort((a, b) =>
              a.name.toLowerCase() > b.name.toLowerCase()
                ? 1
                : a.name.toLowerCase() < b.name.toLowerCase()
                ? -1
                : 0
            );

            return {
              collection: {
                ...prev.collection,
                things: newThings,
              },
            };
          } else if (this.props.collectionId === prev.collection.id) {
            const newThings = prev.collection.things
              .filter(
                (thing) => thing.id !== subscriptionData.data.thingMoved.id
              )
              .sort((a, b) =>
                a.name.toLowerCase() > b.name.toLowerCase()
                  ? 1
                  : a.name.toLowerCase() < b.name.toLowerCase()
                  ? -1
                  : 0
              );

            return {
              collection: {
                ...prev.collection,
                things: newThings,
              },
            };
          }
        },
      });

      this.props.collectionData.subscribeToMore({
        document: gql`
          subscription {
            collectionDeleted
          }
        `,
        variables: { collectionId: this.props.collectionId },
        updateQuery: () => this.setState({ redirectToCollections: true }),
      });
    }

    render() {
      const {
        collectionData: { error, collection },
      } = this.props;
      const { REACT_APP_FULLSCREEN_BODY_BACKGROUND: fullscreenBackground } =
        process.env;

      if (error) {
        if (
          error.message ===
          "GraphQL error: You are not allowed to perform this operation"
        ) {
          this.setState({ redirectToCollections: true });
        }
      }

      if (this.state.redirectTo) {
        this.setState({ redirectTo: "" });
      }

      if (this.state.deselectThing) {
        this.setState({ deselectThing: false });
      }

      return (
        <>
          <IsOnline
            onChange={(online) => {
              if (online) {
                this.props.collectionData.refetch();
              }
            }}
          />
          <Helmet>
            <title>
              {collection
                ? collection.things.filter(
                    (thing) =>
                      thing.id ===
                      querystringify.parse(
                        window.cordova &&
                          window.location.hash.split("?").length === 2
                          ? "?" + window.location.hash.split("?")[1]
                          : window.location.search
                      ).thing
                  )[0]
                  ? appName +
                    " - " +
                    collection.things.filter(
                      (thing) =>
                        thing.id ===
                        querystringify.parse(
                          window.cordova &&
                            window.location.hash.split("?").length === 2
                            ? "?" + window.location.hash.split("?")[1]
                            : window.location.search
                        ).thing
                    )[0].name
                  : appName + " - " + collection.name
                : appName}
            </title>
          </Helmet>
          {this.props.mobile ? (
            <div
              className="mobileMain"
              style={{
                overflowY: "hidden",
              }}
            >
              {this.props.selectedThing == null ? (
                <>
                  <div
                    key="sidebar"
                    style={{
                      backgroundColor: "transparent",
                      height: "calc(100% - 64px - env(safe-area-inset-bottom))",
                      overflowY: "hidden",
                    }}
                  >
                    <Sidebar
                      selectThing={(id) => {
                        this.props.selectThing(id);
                        this.setState({ drawer: false });
                      }}
                      selectedThing={this.props.selectedThing}
                      changeDrawerState={this.changeDrawerState}
                      isMobile
                      collectionData={this.props.collectionData}
                      selectedCollection={this.props.collectionId}
                      searchThings={this.props.searchThings}
                      searchText={this.props.thingsSearchText}
                      snackbarOpen={this.props.snackbarOpen}
                      userData={this.props.userData}
                      client={this.props.client}
                      fabWidth={this.state.fabWidth}
                      setFabWidth={(fabWidth) => this.setState({ fabWidth })}
                    />
                  </div>
                  <AppBar
                    position="sticky"
                    style={{
                      boxShadow:
                        "0px -2px 4px -1px rgba(0,0,0,0.2), 0px -4px 5px 0px rgba(0,0,0,0.14), 0px -1px 10px 0px rgba(0,0,0,0.12)",
                      backgroundColor,
                    }}
                  >
                    <SidebarHeader
                      logOut={this.props.logOut}
                      key="sidebarHeader"
                      collectionName={
                        this.props.collectionData.collection?.name
                      }
                      myRole={this.props.collectionData.collection?.myRole}
                      snackbarOpen={this.props.snackbarOpen}
                      mobile
                      fabWidth={this.state.fabWidth}
                    />
                  </AppBar>
                </>
              ) : (
                <ThingFetcher
                  changeDrawerState={this.changeDrawerState}
                  thingId={this.props.selectedThing}
                  drawer={this.state.drawer}
                  showHidden={this.state.showMainHidden}
                  changeShowHiddenState={this.changeShowHiddenState}
                  hiddenNotifications={this.state.hiddenNotifications}
                  showHiddenNotifications={this.showHiddenNotifications}
                  collectionData={this.props.collectionData}
                  isMobile
                  logOut={this.props.logOut}
                  collections={this.props.collections}
                  userData={this.props.userData}
                  client={this.props.client}
                />
              )}
            </div>
          ) : (
            <div
              className="main"
              style={{
                gridTemplateRows:
                  "[start] calc(64px + " +
                  getNotchHeight("top") +
                  ") [mid] auto [end]",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            >
              <div className="invisibleHeader" key="invisibleHeader" />
              <SidebarHeader
                logOut={this.props.logOut}
                key="sidebarHeader"
                collectionName={this.props.collectionData.collection?.name}
                myRole={this.props.collectionData.collection?.myRole}
                emailIsVerified={this.props.userData.user?.emailIsVerified}
                fabWidth={this.state.fabWidth}
              />
              <div
                className="sidebar"
                key="sidebar"
                style={{
                  background: "transparent",
                  display: "flex",
                }}
              >
                <Sidebar
                  selectThing={(id) => {
                    this.props.selectThing(id);
                    this.setState({ drawer: false });
                  }}
                  selectedThing={this.props.selectedThing}
                  changeDrawerState={this.changeDrawerState}
                  collectionData={this.props.collectionData}
                  selectedCollection={this.props.collectionId}
                  searchThings={this.props.searchThings}
                  searchText={this.props.thingsSearchText}
                  userData={this.props.userData}
                  client={this.props.client}
                  fabWidth={this.state.fabWidth}
                  setFabWidth={(fabWidth) => this.setState({ fabWidth })}
                />
              </div>
              {this.props.selectedThing !== null ? (
                <ThingFetcher
                  changeDrawerState={this.changeDrawerState}
                  drawer={this.state.drawer}
                  thingId={this.props.selectedThing}
                  showHidden={this.state.showMainHidden}
                  changeShowHiddenState={this.changeShowHiddenState}
                  hiddenNotifications={this.state.hiddenNotifications}
                  showHiddenNotifications={this.showHiddenNotifications}
                  collectionData={this.props.collectionData}
                  isMobile={false}
                  logOut={this.props.logOut}
                  collections={this.props.collections}
                  userData={this.props.userData}
                  client={this.props.client}
                />
              ) : (
                <>
                  <div
                    style={{
                      gridArea: "mainBodyHeader",
                      backgroundColor: lightColor,
                    }}
                    key="mainBodyHeader"
                  />
                  <div style={{ backgroundColor }} className="mainBody">
                    <div
                      className="mainBodyBG"
                      style={{
                        backgroundImage: "url(" + backgroundLogo + ")",
                        ...(fullscreenBackground
                          ? {
                              backgroundSize: "contain",
                              backgroundPosition: "bottom",
                            }
                          : {}),
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          )}
          {this.state.redirectTo && collection && (
            <Redirect
              push
              to={
                "/?collection=" +
                collection.id +
                "&thing=" +
                this.state.redirectTo
              }
            />
          )}
          {this.state.deselectThing && collection && (
            <Redirect push to={"/?collection=" + collection.id} />
          )}
          {this.state.redirectToCollections && <Redirect push to="/" />}
        </>
      );
    }
  }
);
