import { gql, useMutation } from "@apollo/client";
import { isNoneNullish, useUserLocation } from "@igloocloud/igloosharedui";
import { Check, FullscreenExit } from "@mui/icons-material";
import { CircularProgress, Dialog, Fab, Zoom } from "@mui/material";
import L from "leaflet";
import { useRef, useState } from "react";
import Props from "./DrawMap.types";

const mapId = "draw-dialog-map";

const userMarker =
  '<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"> <g filter="url(#filter0_d_101_2)">  <circle cx="16" cy="16" r="12" fill="#0057CB"/>  </g>  <path d="M16 15.9792C15.0833 15.9792 14.3333 15.6875 13.75 15.1042C13.1667 14.5208 12.875 13.7708 12.875 12.8542C12.875 11.9375 13.1667 11.1875 13.75 10.6042C14.3333 10.0208 15.0833 9.72917 16 9.72917C16.9167 9.72917 17.6667 10.0208 18.25 10.6042C18.8333 11.1875 19.125 11.9375 19.125 12.8542C19.125 13.7708 18.8333 14.5208 18.25 15.1042C17.6667 15.6875 16.9167 15.9792 16 15.9792ZM9.33333 22.6667V20.7083C9.33333 20.1806 9.46528 19.7292 9.72917 19.3542C9.99305 18.9792 10.3333 18.6944 10.75 18.5C11.6806 18.0833 12.5729 17.7708 13.4271 17.5625C14.2812 17.3542 15.1389 17.25 16 17.25C16.8611 17.25 17.7153 17.3576 18.5625 17.5729C19.4097 17.7882 20.2981 18.0986 21.2275 18.504C21.6623 18.7002 22.0108 18.9847 22.2731 19.3575C22.5355 19.7303 22.6667 20.1806 22.6667 20.7083V22.6667H9.33333Z" fill="white"/>  <defs>  <filter id="filter0_d_101_2" x="0" y="0" width="32" height="32" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">  <feFlood flood-opacity="0" result="BackgroundImageFix"/>  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>  <feOffset/>  <feGaussianBlur stdDeviation="2"/>  <feComposite in2="hardAlpha" operator="out"/>  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_101_2"/>  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_101_2" result="shape"/>  </filter>  </defs>  </svg>';
export const userMarkerUrl = "data:image/svg+xml;base64," + btoa(userMarker);

const pinMarker =
  '<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><g filter="url(#filter0_d_302_4)"><circle cx="16" cy="16" r="12" fill="#0057CB"/></g><path d="M19.3333 16V9.33333H20.1667V7.66667H11.8333V9.33333H12.6667V16L11 17.6667V19.3333H15.3333V24.3333H16.6667V19.3333H21V17.6667L19.3333 16Z" fill="white"/><defs><filter id="filter0_d_302_4" x="0" y="0" width="32" height="32" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset/><feGaussianBlur stdDeviation="2"/><feComposite in2="hardAlpha" operator="out"/><feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_302_4"/><feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_302_4" result="shape"/></filter></defs></svg>';
export const pinMarkerUrl = "data:image/svg+xml;base64," + btoa(pinMarker);

const DrawMap = ({ close, collectionId, geometryId }: Props) => {
  const {
    REACT_APP_LIGHT_COLOR: lightColor,
    REACT_APP_MAIN_BACKGROUND_COLOR: mainBackgroundColor,
  } = process.env;

  const [createGeometry] = useMutation(gql`
    mutation createGeometry($collectionId: ID!, $geoJSON: GeoJSON!) {
      createGeometry(
        collectionId: $collectionId
        geoJSON: $geoJSON
        type: "main"
      ) {
        id
      }
    }
  `);
  const [updateGeometry] = useMutation(gql`
    mutation updateGeometry($id: ID!, $geoJSON: GeoJSON!) {
      updateGeometry(id: $id, geoJSON: $geoJSON) {
        id
      }
    }
  `);

  const [pins, setPins] = useState<[number, number][][]>([]);

  const map = useRef<L.Map>(null);
  const tileLayer = useRef<L.TileLayer>(null);
  const [userLatitude, userLongitude] = useUserLocation();

  setTimeout(() => {
    let tempMap = map.current;

    if (
      document.getElementById(mapId) &&
      !document.getElementById(mapId).childNodes.length
    ) {
      tempMap = L.map(mapId, {
        attributionControl: false,
        zoomControl: false,
      }).setView(
        isNoneNullish(userLatitude, userLongitude)
          ? [userLatitude, userLongitude]
          : [45.9, 10.8],
        13,
        13
      );

      tileLayer.current = L.tileLayer(
        "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
        {
          maxZoom: 19,
        }
      ).addTo(tempMap);

      if (userLatitude && userLongitude) {
        L.marker([userLatitude, userLongitude], {
          icon: L.icon({ iconUrl: userMarkerUrl, iconAnchor: [12, 12] }),
        }).addTo(tempMap);
      }

      tempMap.on("click", (event) => {
        setPins((pins) => [...pins, [event.latlng.lng, event.latlng.lat]]);

        L.marker(event.latlng, {
          icon: L.icon({ iconUrl: pinMarkerUrl, iconAnchor: [12, 12] }),
        }).addTo(tempMap);
      });
    }

    map.current = tempMap;
  }, 0);

  return (
    <Dialog open fullScreen>
      <div id={mapId} style={{ width: "100%", height: "100%" }} />
      {!(
        document.getElementById(mapId) &&
        !document.getElementById(mapId).childNodes.length
      ) && (
        <CircularProgress
          size={96}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            margin: "auto",
            color: lightColor,
            zIndex: -1,
          }}
        />
      )}
      <Fab
        style={{
          position: "absolute",
          top: 24,
          right: 24,
          zIndex: 1000,
          backgroundColor: mainBackgroundColor,
        }}
        onClick={close}
      >
        <FullscreenExit />
      </Fab>
      <Zoom in={pins.length >= 4}>
        <Fab
          style={{
            position: "absolute",
            right: 24,
            bottom: 24,
            zIndex: 1000,
            backgroundColor: mainBackgroundColor,
          }}
          onClick={() =>
            (geometryId
              ? updateGeometry({
                  variables: {
                    id: geometryId,
                    geoJSON: JSON.stringify({
                      coordinates: [[...pins, pins[0]]],
                      type: "Polygon",
                    }),
                  },
                })
              : createGeometry({
                  variables: {
                    collectionId,
                    geoJSON: JSON.stringify({
                      coordinates: [[...pins, pins[0]]],
                      type: "Polygon",
                    }),
                  },
                })
            ).then(close)
          }
        >
          <Check />
        </Fab>
      </Zoom>
    </Dialog>
  );
};

export default DrawMap;
